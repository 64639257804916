import './marketing.css'
export default function Marketing () {
    return(
        <div className='container-main'>

         <div className='image2'></div>

        <div className='image'></div>

        </div>
    )
}