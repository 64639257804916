const data = {
    status: "success",
    items: [
        /*{
            id: 1,
            name: "colar",
            image: require('../assets/produtos/choker.jpeg'),
            image2: require('../assets/produtos/choker2.jpeg'),
            image3: require('../assets/tamanhos-colar.webp'),
            title: "CHOKER PONTO DE LUZ",
            price: "79,99",
            priceNow: "ESGOTADO",
            overview: "Choker Ponto de Luz 40cm. Um colar com um design exclusivo e com cada detalhe pensado para completar e trazer mais beleza ao estilo de cada mulher.",
            parcel: "5x de R$ 11,99",
            buy: "https://wa.link/f4z9yt"
        },
        {
            id: 3,
            name: "colar",
            image: require('../assets/produtos/colar-fe.jpeg'),
            image2: require('../assets/produtos/colar-fe2.jpeg'),
            image3: require('../assets/tamanhos-colar.webp'),
            title: "COLAR PRATA FÉ",
            price: "89,99",
            priceNow: "ESGOTADO",
            overview: "Colar Prata Fé - 40cm. Aquela joia que vai te conquistar, um modelo simples e delicado. Um lembrete diário para carregar junto ao coração.",
            parcel: "5x de R$ 15,60",
            buy: "https://wa.link/lisc25"
        },
        {
            id: 4,
            name: "colar",
            image: require('../assets/produtos/corrente-lua.jpeg'),
            image2: require('../assets/produtos/corrente-lua2.jpeg'),
            image3: require('../assets/tamanhos-colar.webp'),
            title: "CORRENTE LUA",
            price: "139,99",
            priceNow: "R$ 114,99",
            overview: "Corrente Pingente de Lua 45cm. Uma joia que lembra o céu, o lugar mais lindo e misterioso que temos, essa prata com certeza irá lhe admirar.",
            parcel: "5x de R$ 22,99",
            buy: "https://wa.link/haue93"
        },*/
        {
            id: 5,
            name: "brinco",
            image: require('../assets/produtos/argola.jpeg'),
            image2: require('../assets/produtos/argola.jpeg'),
            image3: require('../assets/produtos/argola.jpeg'),
            title: "ARGOLA MEIA CANA",
            price: "69,99",
            priceNow: "R$ 55,99",
            overview: "Argola Meia Cana Grossa 2cm. O brinco de argola em prata é uma peça queridinha e indispensável no porta-joias.",
            parcel: "5x de 11,20",
            buy: "https://wa.link/z6i5rv"
        },
        {
            id: 6,
            name: "piercing",
            image: require('../assets/produtos/piercing.jpeg'),
            image2: require('../assets/produtos/piercing.jpeg'),
            image3: require('../assets/produtos/piercing.jpeg'),
            title: "PIERCING PALITO",
            price: "99,99",
            priceNow: "R$ 85,99",
            overview: "Piercing Palito Com Estrela. Perfeito para quem gosta de looks ousados e modernos. Design minimalista perfeito para montar diversas combinações incríveis, inspire-se e surpreenda-se",
            parcel: "5x de 17,20",
            buy: "https://wa.link/niwkzq"
        },
        {
            id: 7,
            name: "masculino",
            image: require('../assets/produtos/corrente-masc.jpeg'),
            image2: require('../assets/produtos/corrente-masculina-60cm-2.jpeg'),
            image3: require('../assets/tamanhos-colar.webp'),
            title: "CORRENTE MASCULINA 60CM",
            price: "124,99",
            priceNow: "R$ 94,99",
            overview: "Corrente Masculina 60cm. Os colares de corrente transformam qualquer visual básico, deixando você super estiloso e com presença.",
            parcel: "5x de 19,00",
            buy: "https://wa.link/1qnaj9"
        },
        {
            id: 8,
            name: "anel",
            image: require('../assets/produtos/anel-liso.jpeg'),
            image2: require('../assets/produtos/anel-liso2.JPG'),
            image3: require('../assets/produtos/anel-liso.jpeg'),
            title: "ANEL TRIÂNGULO LISO",
            price: "49,99",
            priceNow: "R$ 38,99",
            overview: "Anel de Prata Triângulo Liso 14cm. Com um design liso e simples, o anel se encaixa perfeitamente em qualquer look e ocasião.",
            parcel: "5x de 7,80",
            buy: "https://wa.link/ff6lqk"
        },
        {
            id: 9,
            name: "brinco",
            image: require('../assets/produtos/brinco-ponto.jpeg'),
            image2: require('../assets/produtos/brinco-ponto.jpeg'),
            image3: require('../assets/produtos/brinco-ponto.jpeg'),
            title: "BRINCO PONTO DE LUZ",
            price: "46,50",
            priceNow: "R$ 24,99",
            overview: "Brinco Ponto de Luz Quadrado 9x9. Com certeza esse será o brinco favorito da sua coleção, se encaixa a qualquer ambiente, dia a dia, festas, trabalho(leve, básico e confortável",
            parcel: "5x de 4,99",
            buy: "https://wa.link/e7p04t" 
        },
        /*{
            id: 10,
            name: "brinco",
            image: require('../assets/produtos/brinco-trio-coracao.jpeg'),
            image2: require('../assets/produtos/brinco-trio-coracao.jpeg'),
            image3: require('../assets/produtos/brinco-trio-coracao.jpeg'),
            title: "BRINCO TRIO CORAÇÃO",
            price: "75,99",
            priceNow: "R$ 62,99",
            overview: "Brinco Trio Coração Zircônia. Com tamanhos em 4mm / 6mm / 8mm. O brinco é o toque final que ilumina o meu estilo, um detalhe que não pode faltar no seu look.",
            parcel: "5x de 12,60",
            buy: "https://wa.link/32uugj"
        },
        {
            id: 11,
            name: "brinco",
            image: require('../assets/produtos/brinco-trio-cor.jpeg'),
            image2: require('../assets/produtos/brinco-trio-cor.jpeg'),
            image3: require('../assets/produtos/brinco-trio-cor.jpeg'),
            title: "BRINCO TRIO COLORIDO",
            price: "59,99",
            priceNow: "R$ 46,99",
            overview: "Brinco Trio Corações Colorido. Com tamanho de 4mm. Brilhe com confiança, use um brinco como uma coroa de estilo!, se tem um brinco que que te surpreenderá em belza, com certeza é esse.",
            parcel: "5x de 9,40",
            buy: "https://wa.link/hobdlw"
        },
        {
            id: 12,
            name: "brinco",
            image: require('../assets/produtos/brinco-trio-rosa.jpeg'),
            image2: require('../assets/produtos/brinco-trio-rosa.jpeg'),
            image3: require('../assets/produtos/brinco-trio-rosa.jpeg'),
            title: "BRINCO TRIO ROSA",
            price: "59,99",
            priceNow: "R$ 49,99",
            overview: "Brinco Trio Corações Zircônias Coloridos Com tamanhos - 4mm / 5mm / 6mm. Um brinco pode transformar não apenas a sua aparência, mas também a sua confiança e atitude.",
            parcel: "5x de 9,99",
            buy: "https://wa.link/azhnzs"
        },
        {
            id: 13,
            name: "brinco",
            image: require('../assets/produtos/brinco-duplinha.jpeg'),
            image2: require('../assets/produtos/brinco-duplinha-ponto-de-luz.jpeg'),
            image3: require('../assets/produtos/brinco-duplinha.jpeg'),
            title: "BRINCO DUPLO PONTO DE LUZ",
            price: "64,99",
            priceNow: "R$ 49,99",
            overview: "Brinco Duplinha Ponto de Luz. Com tamanhos em 6mm. O brinco certo pode fazer toda a diferença no seu visual, adicionando elegância, brilho e um toque de personalidade que é só seu. Não espere mais para elevar o seu look com um lindo par de brincos.",
            parcel: "5x de 9,99",
            buy: "https://wa.link/u5gcap"
        },
        {
            id: 14,
            name: "brinco",
            image: require('../assets/produtos/argola-lisa.jpeg'),
            image2: require('../assets/produtos/argola-lisa.jpeg'),
            image3: require('../assets/produtos/argola-lisa.jpeg'),
            title: "ARGOLA LISA",
            price: "149,99",
            priceNow: "R$ 126,99",
            overview: "Argola Lisa 55mm. Uma argola é mais do que uma joia, é uma declaração de estilo e uma expressão de personalidade.",
            parcel: "5x de 25,40",
            buy: "https://wa.link/q7sye3"
        },
        {
            id: 15,
            name: "brinco",
            image: require('../assets/produtos/brinco-luz-coracao.jpeg'),
            image2: require('../assets/produtos/brinco-luz-coracao.jpeg'),
            image3: require('../assets/produtos/brinco-luz-coracao.jpeg'),
            title: "BRINCO CORAÇÃO",
            price: "44,99",
            priceNow: "R$ 33,99",
            overview: "Brinco Ponto de Luz Coração. O brinco é o toque de estilo que completa o seu visual, essa joia não pode ficar de fora da sua coleção.",
            parcel: "5x de 6,80",
            buy: "https://wa.link/4h86xt"
        },*/
        {
            id: 16,
            name: "anel",
            image: require('../assets/produtos/anel-4-fio.jpeg'),
            image2: require('../assets/produtos/anel-4-fio2.jpeg'),
            image3: require('../assets/produtos/anel-4-fio.jpeg'),
            title: "ANEL 4 FIOS",
            price: "149,99",
            priceNow: "R$ 123,99",
            overview: "Anel 4 Fios 3 Zircônias Coloridas, com tamanho de 16cm. Um anel é o símbolo do seu poder interior e da sua elegância exterior.",
            parcel: "5x de 24,80",
            buy: "https://wa.link/b7tvuf"
        },
        {
            id: 17,
            name: "anel",
            image: require('../assets/produtos/anel-dois-coracoes.jpeg'),
            image2: require('../assets/produtos/anel-dois-coracoes2.JPG'),
            image3: require('../assets/produtos/anel-dois-coracoes.jpeg'),
            title: "ANEL DOIS CORAÇÕES",
            price: "119,99",
            priceNow: "R$ 104,99",
            overview: "Anel Dois Corações Entrelaçados, com tamanhode 16cm. Seu anel é o símbolo do seu poder e elegância, uma joia que brilha com a sua personalidade.",
            parcel: "5x de 20,99",
            buy: "https://wa.link/en2wdk"
        },
        {
            id: 19,
            name: "anel",
            image: require('../assets/produtos/anel-infinito.jpeg'),
            image2: require('../assets/produtos/anel-infinito2.JPG'),
            image3: require('../assets/produtos/anel-infinito.jpeg'),
            title: "ANEL INFINITO",
            price: "59,99",
            priceNow: "R$ 43,99",
            overview: "Anel Infinito - 16cm. O anel é a joia que marca seu compromisso com o amor, uma promessa eterna gravada em metal precioso.",
            parcel: "5x de 8,80",
            buy: "https://wa.link/stz98z"
        },
        {
            id: 20,
            name: "berloque",
            image: require('../assets/produtos/berloque-tabua.jpeg'),
            image2: require('../assets/produtos/berloque-tabua.jpeg'),
            image3: require('../assets/produtos/berloque-tabua.jpeg'),
            title: "BERLOQUE TÁBUA",
            price: "69,99",
            priceNow: "R$ 56,99",
            overview: "Berloque Tábua com Faca. Pequenos detalhes, grandes lembranças, adquira berloques e eternize momentos!",
            parcel: "5x de 11,40",
            buy: "https://wa.link/2usxeu"
        },
        {
            id: 21,
            name: "berloque",
            image: require('../assets/produtos/berloque-pedras.jpeg'),
            image2: require('../assets/produtos/berloque-pedras.jpeg'),
            image3: require('../assets/produtos/berloque-pedras.jpeg'),
            title: "BERLOQUE PEDRA CLARA",
            price: "75,99",
            priceNow: "R$ 64,99",
            overview: "Berloque com Pedra Clara. Os berloques são como pequenos tesouros que contam a história da sua vida, eternize cada momento com um!",
            parcel: "5x de 12,99",
            buy: "https://wa.link/ec69ud"
        },
        {
            id: 22,
            name: "berloque",
            image: require('../assets/produtos/berloque-pedras.jpeg'),
            image2: require('../assets/produtos/berloque-pedras.jpeg'),
            image3: require('../assets/produtos/berloque-pedras.jpeg'),
            title: "BERLOQUE PEDRA COLORIDA",
            price: "75,99",
            priceNow: "R$ 64,99",
            overview: "Berloque com Pedra Colorida. Pequenos detalhes, grandes histórias. Complete sua pulseira com nossos encantadores berloques.",
            parcel: "5x de 12,99",
            buy: "https://wa.link/m0o1wg"
        },
        {
            id: 24,
            name: "anel",
            image: require('../assets/produtos/anel-quadrado.jpeg'),
            image2: require('../assets/produtos/anel-quadrado2.JPG'),
            image3: require('../assets/produtos/anel-quadrado.jpeg'),
            title: "ANEL QUADRADO",
            price: "81,99",
            priceNow: "R$ 61,99",
            overview: "Anel Quadrado - 16cm. O seu anel não é apenas uma joia, é uma extensão da sua história, um tesouro que carrega consigo em todos os momentos.",
            parcel: "5x de 12,40",
            buy: "https://wa.link/y6l80v"
        },
        {
            id: 25,
            name: "anel",
            image: require('../assets/produtos/anel-v.jpeg'),
            image2: require('../assets/produtos/anel-v2.jpeg'),
            image3: require('../assets/produtos/anel-v.jpeg'),
            title: "ANEL V",
            price: "64,99",
            priceNow: "R$ 51,99",
            overview: "Anel V com Bolinhas - 19cm. O seu anel é uma expressão de amor próprio e confiança, uma joia que ilumina sua jornada.",
            parcel: "5x de 10,40",
            buy: "https://wa.link/835yne"
        },
        {
            id: 26,
            name: "infantil",
            image: require('../assets/produtos/infantil1.jpeg'),
            image2: require('../assets/produtos/pulseira-infantil2.jpeg'),
            image3: require('../assets/produtos/infantil1.jpeg'),
            title: "PULSEIRA INFANTIL",
            price: "59,99",
            priceNow: "R$ 40,99",
            overview: "Pulseira Feminina Infantil 1,1GR. Uma pulseira é um tesouro que carrega as memórias mais doces da infância, uma joia que acompanha as aventuras de quem mais amamos.",
            parcel: "5x de 8,20",
            buy: "https://wa.link/hdvwrq"
        },
        {
            id: 27,
            name: "anel",
            image: require('../assets/produtos/anel-5-coracoes.jpeg'),
            image2: require('../assets/produtos/anel-5-coracoes2.jpeg'),
            image3: require('../assets/produtos/anel-5-coracoes.jpeg'),
            title: "ANEL 5 CORAÇÕES",
            price: "59,99",
            priceNow: "ESGOTADO",
            overview: "Anel 5 Corações Lisos - 16cm. Um anel é mais do que uma simples joia, é uma história de amor, um compromisso com a beleza e uma celebração da vida.",
            parcel: "5x de 8,99",
            buy: "https://wa.link/6jrkrj"
        },
        {
            id: 28,
            name: "anel",
            image: require('../assets/produtos/anel-no.jpeg'),
            image2: require('../assets/produtos/anel-no2.jpeg'),
            image3: require('../assets/produtos/anel-no2.jpeg'),
            title: "ANEL LISO NÓ",
            price: "79,99",
            priceNow: "R$ 67,99",
            overview: "Anel Liso Nó - 16cm. Um anel é um círculo de amor que envolve nosso coração, uma joia que simboliza nossas conexões mais preciosas, esse é um anel que não pode faltar em sua coleção.",
            parcel: "5x de 13,60",
            buy: "https://wa.link/bpsojz"
        },
        {
            id: 29,
            name: "anel",
            image: require('../assets/produtos/anel-torcido.jpeg'),
            image2: require('../assets/produtos/anel-torcido2.jpeg'),
            image3: require('../assets/produtos/anel-torcido2.jpeg'),
            title: "ANEL TORCIDO",
            price: "74,99",
            priceNow: "R$ 58,99",
            overview: "Anel Torcido - 16cm. Com um anel, não apenas adornamos nossos dedos, mas também celebramos nossa individualidade e expressamos nossa personalidade de forma única e elegante, com certeza esse anel é a sua cara.",
            parcel: "5x de 11,80",
            buy: "https://wa.link/evulx0"
        },
        /*{
            id: 30,
            name: "pulseira",
            image: require('../assets/produtos/pulseira-bolinhas.jpeg'),
            image2: require('../assets/produtos/pulseira-bolinhas2.jpeg'),
            image3: require('../assets/produtos/pulseira-bolinhas2.jpeg'),
            title: "PULSEIRA FEMININA",
            price: "89,99",
            priceNow: "R$ 73,99",
            overview: "Pulseira Feminina de Bolinhas - 17 a 20cm. Sua pulseira é mais do que um acessório, é uma extensão da sua personalidade, uma peça que conta a nossa história e acompanha os momentos mais especiais da vida. Aproveite seus momentos com DelPlata.",
            parcel: "5x de 14,80",
            buy: "https://wa.link/43hb2n"
        },
        {
            id: 31,
            name: "pulseira",
            image: require('../assets/produtos/pulseira-bolas.jpeg'),
            image2: require('../assets/produtos/pulseira-bolas2.jpeg'),
            image3: require('../assets/produtos/pulseira-bolas2.jpeg'),
            title: "PULSEIRA FEMININA",
            price: "129,99",
            priceNow: "ESGOTADO",
            overview: "Pulseira Feminina de Bolas - 17,5 a 21,5cm. Uma pulseira é a escolha perfeita para quem quer adicionar um toque de estilo e sofisticação a qualquer look, enquanto carrega consigo um símbolo de história e significado. Simbolize sua história com um produto DelPlata.",
            parcel: "5x de 23,80",
            buy: "https://wa.link/ge3e98"
        },*/
        {
            id: 32,
            name: "pulseira",
            image: require('../assets/produtos/pulseira-olho-grego.jpeg'),
            image2: require('../assets/produtos/pulseira-olho-grego2.jpeg'),
            image3: require('../assets/produtos/pulseira-olho-grego2.jpeg'),
            title: "PULSEIRA OLHO GREGO",
            price: "69,99",
            priceNow: "R$ 51,99",
            overview: "Pulseira Olho Grego - 18cm. Com a pulseira de olho grego, você se sente protegido e envolvido por energias positivas, pronto para enfrentar qualquer desafio com confiança.",
            parcel: "5x de 10,40",
            buy: "https://wa.link/s1nuog"
        },
        {
            id: 34,
            name: "pulseira",
            image: require('../assets/produtos/pulseira-3-flores.jpeg'),
            image2: require('../assets/produtos/pulseira-3-flores2.jpeg'),
            image3: require('../assets/produtos/pulseira-3-flores2.jpeg'),
            title: "PULSEIRA 3 FLORES",
            price: "89,99",
            priceNow: "R$ 70,99",
            overview: "Pulseira 3 Flores - 20cm. Uma pulseira pode ser o toque final perfeito para qualquer look, seja casual ou formal. Compre uma pulseira que reflita sua personalidade e estilo, e você terá uma peça única que pode ser apreciada por anos.",
            parcel: "5s de 14,20",
            buy: "https://wa.link/1bg4z2"
        },
        {
            id: 35,
            name: "pulseira",
            image: require('../assets/produtos/pulseira-folha.jpeg'),
            image2: require('../assets/produtos/pulseira-folha2.jpeg'),
            image3: require('../assets/produtos/pulseira-folha2.jpeg'),
            title: "PULSEIRA FOLHA",
            price: "69,99",
            priceNow: "R$ 53,99",
            overview: "Pulseira Folha - 18.5cm. Uma pulseira pode ser o detalhe que faltava para complementar o seu look e torná-lo ainda mais elegante e sofisticado. Invista em uma pulseira de qualidade e tenha a certeza de estar adicionando um toque de charme e estilo ao seu visual.",
            parcel: "5x de 10,80",
            buy: "https://wa.link/h1hle9"
        },
        {
            id: 36,
            name: "pulseira",
            image: require('../assets/produtos/pulseira-borboleta.jpeg'),
            image2: require('../assets/produtos/pulseira-borboleta2.jpeg'),
            image3: require('../assets/produtos/pulseira-borboleta2.jpeg'),
            title: "PULSEIRA BORBOLETA",
            price: "67,99",
            priceNow: "ESGOTADO",
            overview: "Pulseira Borboleta - 18.5cm. Uma pulseira é mais do que um simples acessório - ela pode ser uma maneira de expressar sua personalidade e estilo. Além disso, uma pulseira bem escolhida pode ser um presente perfeito para alguém especial em sua vida.",
            parcel: "5x de 11,20",
            buy: "https://wa.link/egsd73"
        },
        {
            id: 37,
            name: "anel",
            image: require('../assets/produtos/anel-estrela.jpeg'),
            image2: require('../assets/produtos/anel-estrela2.jpeg'),
            image3: require('../assets/produtos/anel-estrela2.jpeg'),
            title: "ANEL ESTRELA",
            price: "49,99",
            priceNow: "R$ 35,99",
            overview: "Anel Estrela Lisa - 16cm. Um anel é mais do que uma joia, é uma lembrança dos momentos especiais que carrega com si mesma. Invista em um anel de alta qualidade e duradouro, e você estará criando um tesouro para toda a vida.",
            parcel: "5x de 7,50",
            buy: "https://wa.link/8l8w3e"
        },
        /*{
            id: 38,
            name: "anel",
            image: require('../assets/produtos/anel-seta.jpeg'),
            image2: require('../assets/produtos/anel-seta2.jpeg'),
            image3: require('../assets/produtos/anel-seta2.jpeg'),
            title: "ANEL SETA",
            price: "59,99",
            priceNow: "R$ 41,99",
            overview: "Anel Seta de Lado - 17cm. O anel seta é uma escolha elegante e sofisticada que adiciona um toque de brilho sutil às suas mãos. Além disso, sua durabilidade e resistência a manchas garantem que você possa apreciar seu brilho impecável por muitos anos.",
            parcel: "5x de 8,40",
            buy: "https://wa.link/cfg1w2"
        },
        {
            id: 39,
            name: "anel",
            image: require('../assets/produtos/anel-coracao-vazado.jpeg'),
            image2: require('../assets/produtos/anel-coracao-vazado2.jpeg'),
            image3: require('../assets/produtos/anel-coracao-vazado2.jpeg'),
            title: "ANEL CORAÇÃO VAZADO",
            price: "89,99",
            priceNow: "R$ 71,99",
            overview: "Anel de Prata, Coração Vazado - 16cm. O anel coração vazado é uma escolha elegante e sofisticada para quem procura uma peça de joalheria durável e atemporal, com a sua elegância, ele pode ser usado em qualquer ocasião, adicionando aquele toque final no look.",
            parcel: "5x de 14,40",
            buy: "https://wa.link/vn4t97"
        },
        {
            id: 40,
            name: "anel",
            image: require('../assets/produtos/anel-coracao-liso.jpeg'),
            image2: require('../assets/produtos/anel-coracao-liso2.jpeg'),
            image3: require('../assets/produtos/anel-coracao-liso2.jpeg'),
            title: "ANEL TORCIDO CORAÇÃO",
            price: "79,99",
            priceNow: "R$ 56,99",
            overview: "Anel de Prata com Aro Torcido e Coração Liso - 16cm. O anel coração é elegante, sofisticado e atemporal. Sua beleza discreta e durabilidade tornam-no uma escolha clássica e versátil para qualquer ocasião.",
            parcel: "5x de 11,40",
            buy: "https://wa.link/vn4t97"
        },*/
        {
            id: 41,
            name: "anel",
            image: require('../assets/produtos/anel-love.jpeg'),
            image2: require('../assets/produtos/anel-love2.jpeg'),
            image3: require('../assets/produtos/anel-love2.jpeg'),
            title: "ANEL LOVE",
            price: "59,99",
            priceNow: "R$ 45,99",
            overview: "Anel Love - 19cm. Com seu brilho único e acabamento impecável, o anel Love acrescenta um toque de classe e distinção a qualquer ocasião. Além disso, sua durabilidade e resistência o tornam uma escolha prática e acessível para quem procura um acessório de alta qualidade.",
            parcel: "5x de 9,20",
            buy: "https://wa.link/ztlxca"
        },
        /*{
            id: 42,
            name: "anel",
            image: require('../assets/produtos/anel-coracao-infinito.jpeg'),
            image2: require('../assets/produtos/anel-coracao-infinito3.jpeg'),
            image3: require('../assets/produtos/anel-coracao-infinito2.jpeg'),
            title: "ANEL CORAÇÃO INFINITO",
            price: "55,99",
            priceNow: "R$ 41,99",
            overview: "Anel Coração Infinito - 16 e 19cm. O anel Coração Infinito é uma escolha elegante e sofisticada para quem procura uma joia versátil e atemporal. Além disso, o anel pode ser facilmente combinado com outras peças de joalheria, tornando-o um item indispensável para qualquer guarda-roupa.",
            parcel: "5x de 8,40",
            buy: "https://wa.link/vww0u9"
        },
        {
            id: 43,
            name: "colar",
            image: require('../assets/produtos/corrente-laminada.jpeg'),
            image2: require('../assets/produtos/corrente-laminada2.jpeg'),
            image3: require('../assets/tamanhos-colar.webp'),
            title: "CORRENTE LAMINADA",
            price: "149,99",
            priceNow: "ESGOTADO",
            overview: "Corrente Laminada - 45cm. Uma corrente é mais do que um acessório, é uma expressão da sua personalidade e estilo. É a forma perfeita de realçar a sua beleza e marcar presença por onde passa. Marque presença usando Del Plata.",
            parcel: "5x de 27,20",
            buy: "https://wa.link/t2i6xm"
        },
        {
            id: 44,
            name: "masculino",
            image: require('../assets/produtos/pulseira-masc-20cm.jpeg'),
            image2: require('../assets/produtos/pulseira-masc-20cm.jpeg'),
            image3: require('../assets/produtos/pulseira-masc-20cm.jpeg'),
            title: "PULSEIRA MASCULINA",
            price: "93,99",
            priceNow: "R$ 74,99",
            overview: "Pulseira Masculina - 20cm. - 3,2GR. Uma pulseira é mais do que um simples adorno, é um símbolo de elegância, sofisticação e personalidade. Invista em uma pulseira de qualidade e eleve o seu estilo a um novo patamar.",
            parcel: "5x de 14,99",
            buy: "https://wa.link/job6ys"
        },*/
        {
            id: 45,
            name: "masculino",
            image: require('../assets/produtos/pulseira-masc2.jpeg'),
            image2: require('../assets/produtos/pulseira-masc2-2.jpeg'),
            image3: require('../assets/produtos/pulseira-masc2.jpeg'),
            title: "PULSEIRA MASCULINA",
            price: "84,99",
            priceNow: "R$ 73,99",
            overview: "Pulseira Masculina - 21.5cm. Um acessório que complementa o seu visual, tornando-o ainda mais charmoso e marcante. Seja para ocasiões formais ou informais, a pulseira certa é o toque final para um look perfeito",
            parcel: "5x de 14,80",
            buy: "https://wa.link/islgne"
        },
        /*{
            id: 46,
            name: "masculino",
            image: require('../assets/produtos/pulseira-masc3.jpeg'),
            image2: require('../assets/produtos/pulseira-masc3.jpeg'),
            image3: require('../assets/produtos/pulseira-masc3.jpeg'),
            title: "PULSEIRA MASCULINA",
            price: "159,99",
            priceNow: "R$ 134,99",
            overview: "Pulseira Masculina - 21cm. Uma pulseira é mais do que um simples adorno, é uma peça que carrega consigo sentimentos e memórias que te acompanharão para sempre. Carregue a sua história com a Del Plata",
            parcel: "5x de 27,00",
            buy: "https://wa.link/9p10d3"
        },*/
        {
            id: 48,
            name: "colar",
            image: require('../assets/produtos/colar-gota.jpeg'),
            image2: require('../assets/produtos/colar-gota2.jpeg'),
            image3: require('../assets/tamanhos-colar.webp'),
            title: "COLAR GOTA",
            price: "99,99",
            priceNow: "R$ 77,99",
            overview: "Colar Gota - 45cm. Este colar é mais do que uma simples peça de joalharia, é uma declaração de elegância e sofisticação. Não perca a chance de adicionar um toque de luxo ao seu guarda-roupa com este colar deslumbrante.",
            parcel: "5x de 15,60",
            buy: "https://wa.link/n11knf"
        },
        /*{
            id: 49,
            name: "colar",
            image: require('../assets/produtos/colar-chave.jpeg'),
            image2: require('../assets/produtos/colar-chave2.jpeg'),
            image3: require('../assets/tamanhos-colar.webp'),
            title: "COLAR CHAVE",
            price: "99,99",
            priceNow: "ESGOTADO",
            overview: "Colar Chave - 45cm. Este colar é a escolha perfeita para adicionar um toque de elegância ao seu visual. Com seu design único e sofisticado, você certamente se destacará em qualquer ocasião.",
            parcel: "5x de 15,60",
            buy: "https://wa.link/ok6h5o"
        },
        {
            id: 50,
            name: "colar",
            image: require('../assets/produtos/colar-estrela.jpeg'),
            image2: require('../assets/produtos/colar-estrela2.jpeg'),
            image3: require('../assets/tamanhos-colar.webp'),
            title: "COLAR ESTRELA",
            price: "99,99",
            priceNow: "R$ 77,99",
            overview: "Colar Estrela - 45cm. A qualidade excepcional deste colar garante que ele durará por muitos anos, tornando-o um investimento valioso para o seu guarda-roupa. Não perca a oportunidade de adquirir este belo acessório e elevar seu estilo a um novo patamar.",
            parcel: "5x de 15,60",
            buy: "https://wa.link/8fou7x"   
        },
        /*{
            id: 51,
            name: "colar",
            image: require('../assets/produtos/choker-coracao.jpeg'),
            image2: require('../assets/produtos/choker-coracao2.jpeg'),
            image3: require('../assets/tamanhos-colar.webp'),
            title: "CHOKER CORAÇÃO",
            price: "99,99",
            priceNow: "ESGOTADO",
            overview: "Choker Coração Lado Zircônia - 40cm. Este colar é a escolha perfeita para quem deseja adicionar um toque de elegância e sofisticação ao seu visual. Ele certamente chamará a atenção de todos ao seu redor.",
            parcel: "5x de 15,60",
            buy: "https://wa.link/gf3ofv"
        },
        {
            id: 52,
            name: "colar",
            image: require('../assets/produtos/choker-olho-grego.jpeg'),
            image2: require('../assets/produtos/choker-olho-grego2.jpeg'),
            image3: require('../assets/tamanhos-colar.webp'),
            title: "CHOKER OLHO GREGO",
            price: "99,99",
            priceNow: "R$ 77,99",
            overview: "Choker Olho Grego Zircônia - 40cm. A escolha perfeita seja para uma ocasião especial ou para o dia a dia, este colar é a peça ideal para complementar o seu estilo único e fazer você se sentir ainda mais bonita e confiante.",
            parcel: "5x de 15,60",
            buy: "https://wa.link/hrgfro"
        },*/
        {
            id: 53,
            name: "anel",
            image: require('../assets/produtos/anel-amor.jpeg'),
            image2: require('../assets/produtos/anel-amor2.jpeg'),
            image3: require('../assets/produtos/anel-amor.jpeg'),
            title: "ANEL AMOR",
            price: "69,99",
            priceNow: "R$ 48,99",
            overview: "Anel Amor - 17cm. Este anel é mais do que uma simples joia, é uma expressão de amor, comprometimento e elegância. Não perca a chance de ter essa joia deslumbrante em sua coleção e desfrute da sensação de ter algo realmente especial e significativo em sua vida.",
            parcel: "5x de 9,80",
            buy: "https://wa.link/t3cqq1"
        },
        /*{
            id: 54,
            name: "anel",
            image: require('../assets/produtos/anel-pandora.jpeg'),
            image2: require('../assets/produtos/anel-pandora2.jpeg'),
            image3: require('../assets/produtos/anel-pandora2.jpeg'),
            title: "ANEL PANDORA",
            price: "79,99",
            priceNow: "R$ 65,99",
            overview: "Anel Pandora - 19cm. Feito com materiais de alta qualidade e design clean, ele simboliza a beleza da simplicidade e a sofisticação do minimalismo. Seja para uma ocasião especial ou para uso diário, este anel é uma escolha perfeita.",
            parcel: "5x de 13,20",
            buy: "https://wa.link/uyb0do"
        },*/
        {
            id: 55,
            name: "anel",
            image: require('../assets/produtos/anel-coracao-cravejado.jpeg'),
            image2: require('../assets/produtos/anel-coracao-cravejado2.jpeg'),
            image3: require('../assets/produtos/anel-coracao-cravejado.jpeg'),
            title: "ANEL CRAVEJADO",
            price: "109,99",
            priceNow: "ESGOTADO",
            overview: "Anel Coração Vazado Cravejado - 16cm. Ao colocar este anel em seu dedo, você estará mostrando ao mundo o seu bom gosto e sua personalidade única. Não perca a oportunidade de adquirir esta joia exclusiva e adicionar um toque de glamour ao seu visual.",
            parcel: "5x de 18,40",
            buy: "https://wa.link/s2vbez"
        },
        /*{
            id: 56,
            name: "anel",
            image: require('../assets/produtos/anel-pedra.jpeg'),
            image2: require('../assets/produtos/anel-pedra2.jpeg'),
            image3: require('../assets/produtos/anel-pedra2.jpeg'),
            title: "ANEL LISO",
            price: "149,99",
            priceNow: "R$ 121,99",
            overview: "Anel Liso com Pedra Coração Embutido - 19cm. Com um design incrivelmente elegante e uma qualidade excepcional, este anel é uma verdadeira obra de arte em forma de joia. Sua beleza atemporal vai iluminar qualquer look e torná-lo ainda mais especial.",
            parcel: "5x de 24,40",
            buy: "https://wa.link/1e3xyv"
        },*/
        {
            id: 57,
            name: "anel",
            image: require('../assets/produtos/anel-solitario.jpeg'),
            image2: require('../assets/produtos/anel-solitario2.jpeg'),
            image3: require('../assets/produtos/anel-solitario2.jpeg'),
            title: "ANEL SOLITÁRIO",
            price: "59,99",
            priceNow: "R$ 49,99",
            overview: "Anel Solitário - 14, 16, 17 e 19cm disponíveis. Não importa se é para uma ocasião especial ou para usar no dia a dia, este anel será um complemento perfeito para qualquer outfit. Não perca a chance de ter essa peça excepcional em sua coleção de joias e faça uma escolha que você irá valorizar por muitos anos.",
            parcel: "5x de 9,99",
            buy: "https://wa.link/jri6tc"
        },
        {
            id: 58,
            name: "masculino",
            image: require('../assets/produtos/corrente-3-elo-1.jpeg'),
            image2: require('../assets/produtos/corrente-3-elo-1.2.jpeg'),
            image3: require('../assets/produtos/corrente-3-elo-3.jpeg'),
            title: "CORRENTE MASCULINA",
            price: "189,99",
            priceNow: "R$ 157,99",
            overview: "Corrente Elo 3 por 1 - 70cm. Com um brilho radiante que nunca perde seu charme, uma corrente de prata é o complemento perfeito para qualquer roupa ou ocasião. Seja para uma ocasião especial ou para uso diário, essa peça de qualidade irá acompanhar você por muitos anos.",
            parcel: "5x de 31,60",
            buy: "https://wa.link/sr2a6k"
        },
        /*{
            id: 59,
            name: "pulseira",
            image: require('../assets/produtos/pulseira-zirconia-pendurada.jpeg'),
            image2: require('../assets/produtos/pulseira-zirconia-pendurada2.jpeg'),
            image3: require('../assets/produtos/pulseira-zirconia-pendurada2.jpeg'),
            title: "PULSEIRA ZIRCÔNIA COLORIDA",
            price: "69,99",
            priceNow: "ESGOTADO",
            overview: "Pulseira Zircônia Coloridas Penduradas - 18cm. A pulseira que você está procurando para dar um toque especial ao seu estilo está aqui. Esta pulseira é perfeita para completar seu look em qualquer ocasião.",
            parcel: "5x de 10,99",
            buy: "https://wa.link/qnanht"
        },
        {
            id: 60,
            name: "pulseira",
            image: require('../assets/produtos/pulseira-zircônia-rosa.jpeg'),
            image2: require('../assets/produtos/pulseira-zircônia-rosa2.jpeg'),
            image3: require('../assets/produtos/pulseira-zircônia-rosa2.jpeg'),
            title: "PULSEIRA ZIRCÔNIA ROSA",
            price: "74,99",
            priceNow: "ESGOTADO",
            overview: "Pulseira Zircônia Rosa - 18cm. Com sua beleza e sofisticação, você não vai querer tirá-la do pulso e com certeza vai receber elogios por onde passar. Não perca a chance de ter essa joia incrível em sua coleção e sinta-se ainda mais especial e bela com a sua nova pulseira.",
            parcel: "5x de 12,99",
            buy: "https://wa.link/md1d54"
        },*/
        {
            id: 61,
            name: "pulseira",
            image: require('../assets/produtos/pulseira-zircônias-coloridas.jpeg'),
            image2: require('../assets/produtos/pulseira-zircônias-coloridas2.jpeg'),
            image3: require('../assets/produtos/pulseira-zircônias-coloridas2.jpeg'),
            title: "PULSEIRA ZIRCÔNIA COLORIDA",
            price: "79,99",
            priceNow: "R$ 70,99",
            overview: "Pulseira Zircônia Colorida - 18cm. Complemente seu estilo com a elegância e sofisticação de uma pulseira única, que irá destacar sua personalidade e tornar cada momento ainda mais especial.",
            parcel: "5x de 14,50",
            buy: "https://wa.link/j20uz0"
        },
        {
            id: 62,
            name: "pulseira",
            image: require('../assets/produtos/pulseira-coracao.jpeg'),
            image2: require('../assets/produtos/pulseira-coracao2.jpeg'),
            image3: require('../assets/produtos/pulseira-coracao2.jpeg'),
            title: "PULSEIRA CORAÇÃO",
            price: "89,99",
            priceNow: "R$ 79,99",
            overview: "Pulseira Coração com Zircônia Vermelha - 18cm. Adicione um toque de elegância e estilo ao seu visual com esta deslumbrante pulseira. Com seu design único e detalhes delicados, ela é a escolha perfeita para complementar qualquer roupa e fazer você se sentir confiante e sofisticada em qualquer ocasião.",
            parcel: "5x de 15,99",
            buy: "https://wa.link/h3hki8"
        },
        /*{
            id: 63,
            name: "pulseira",
            image: require('../assets/produtos/pulseira-zircônia-quadrada.jpeg'),
            image2: require('../assets/produtos/pulseira-zircônia-quadrada.jpeg'),
            image3: require('../assets/produtos/pulseira-zircônia-quadrada.jpeg'),
            title: "PULSEIRA ZIRCÔNIA QUADRADA",
            price: "77,99",
            priceNow: "R$ 67,99",
            overview: "Pulseira Colorida com Zircônia Quadrada - 18cm. Realce sua beleza e elegância com a pulseira perfeita. Combinando estilo e sofisticação, essa pulseira vai te encantar e tornar seus looks ainda mais incríveis. Adquira agora mesmo e sinta-se ainda mais confiante e deslumbrante em qualquer ocasião.",
            parcel: "5x de 13,60",
            buy: "https://wa.link/oqfvwj"
        },*/
        {
            id: 64,
            name: "pulseira",
            image: require('../assets/produtos/pulseira-borboleta-colorida.jpeg'),
            image2: require('../assets/produtos/pulseira-borboleta-colorida2.jpeg'),
            image3: require('../assets/produtos/pulseira-borboleta-colorida2.jpeg'),
            title: "PULSEIRA BORBOLETAS COLORIDAS",
            price: "83,99",
            priceNow: "R$ 73,99",
            overview: "Pulseira Zircônia com Borboletas Coloridas - 18cm. Eleve o seu estilo a um novo patamar com a pulseira perfeita para complementar qualquer look. Seja para uma ocasião especial ou para o dia a dia, esta pulseira é o acessório que você precisa para se sentir ainda mais confiante e elegante.",
            parcel: "5x de 14,80",
            buy: "https://wa.link/jtjwlg"
        },
        /*{
            id: 65,
            name: "pulseira",
            image: require('../assets/produtos/pulseira-olho-grego-redondo.jpeg'),
            image2: require('../assets/produtos/pulseira-olho-grego-redondo2.jpeg'),
            image3: require('../assets/produtos/pulseira-olho-grego-redondo2.jpeg'),
            title: "PULSEIRA OLHO GREGO",
            price: "81,99",
            priceNow: "ESGOTADO",
            overview: "Pulseira Olho Grego Redondo - 16 a 22cm. Adicione um toque de elegância e sofisticação ao seu visual com esta deslumbrante pulseira. Não perca a oportunidade de elevar o seu estilo com esta linda pulseira em seu pulso",
            parcel: "5x de 14,40",
            buy: "https://wa.link/eo8ukk"
        },
        {
            id: 66,
            name: "pulseira",
            image: require('../assets/produtos/pulseira-olho-grego-coração.jpeg'),
            image2: require('../assets/produtos/pulseira-olho-grego-coração2.jpeg'),
            image3: require('../assets/produtos/pulseira-olho-grego-coração2.jpeg'),
            title: "PULSEIRA OLHO GREGO",
            price: "71,99",
            priceNow: "R$ 61,99",
            overview: "Pulseira Olho Grego Coração - 16 a 21cm. Complete o seu look com a pulseira perfeita - um acessório elegante que irá adicionar um toque de sofisticação ao seu estilo. Esta pulseira irá durar por muitos anos e tornar-se uma peça essencial do seu guarda-roupa. ",
            parcel: "5x de 12,40",
            buy: "https://wa.link/zragc6"
        },
        {
            id: 67,
            name: "pulseira",
            image: require('../assets/produtos/pulseira-elo-vazado.jpeg'),
            image2: require('../assets/produtos/pulseira-elo-vazado2.jpeg'),
            image3: require('../assets/produtos/pulseira-elo-vazado2.jpeg'),
            title: "PULSEIRA ELOS VAZADOS",
            price: "174,99",
            priceNow: "R$ 154,99",
            overview: "Pulseira Elos Redondos Vazados - 19,5cm. Complemente o seu estilo com esta pulseira elegante e sofisticada. Feita com materiais de alta qualidade, ela irá destacar a sua beleza e personalidade em qualquer ocasião. Adquira agora e sinta a confiança que uma pulseira única e charmosa pode trazer para o seu visual.",
            parcel: "5x de 32,99",
            buy: "https://wa.link/uvjskq"
        },
        {
            id: 68,
            name: "pulseira",
            image: require('../assets/produtos/pulseira-elos-coracao.jpeg'),
            image2: require('../assets/produtos/pulseira-elos-coracao2.jpeg'),
            image3: require('../assets/produtos/pulseira-elos-coracao2.jpeg'),
            title: "PULSEIRA ELOS CORAÇÃO",
            price: "94,99",
            priceNow: "ESGOTADO",
            overview: "Pulseira Elos de Coração Vazado - 19,5cm. Adicione um toque de elegância ao seu visual com essa deslumbrante pulseira. Feita com materiais de alta qualidade e design sofisticado, ela é o acessório perfeito para completar seu estilo único.",
            parcel: "5x de 14,99",
            buy: "https://wa.link/w61fgs"
        },*/
        {
            id: 69,
            name: "pulseira",
            image: require('../assets/produtos/pulseira-acabamento-envelhecido.jpeg'),
            image2: require('../assets/produtos/pulseira-acabamento-envelhecido2.jpeg'),
            image3: require('../assets/produtos/pulseira-acabamento-envelhecido2.jpeg'),
            title: "PULSEIRA ACABAMENTO ENVELHECIDO",
            overview: "Pulseira Prata com Acabamento Envelhecido - 19cm. Com essa pulseira única, você pode mostrar ao mundo quem você é e o que é importante para você. Feita com materiais de alta qualidade e um design elegante, essa pulseira será a sua companheira perfeita para qualquer ocasião.",
            price: "77,99",
            priceNow: "R$ 67,99",
            parcel: "5x de 13,60",
            buy: "https://wa.link/1t5mcb"
        },
        /*{
            id: 70,
            name: "pulseira",
            image: require('../assets/produtos/pulseira-retalhada.jpeg'),
            image2: require('../assets/produtos/pulseira-retalhada2.jpeg'),
            image3: require('../assets/produtos/pulseira-retalhada2.jpeg'),
            title: "PULSEIRA RETALHADA",
            overview: "Pulseira Prata Retalhada - 19cm. Complete seu look com estilo e sofisticação usando a pulseira perfeita. Nossa pulseira é a escolha ideal para dar um toque final elegante a qualquer roupa. Com design exclusivo e materiais de alta qualidade, você se sentirá confiante e estiloso(a) usando nossa pulseira.",
            price: "129,99",
            priceNow: "R$ 109,99",
            parcel: "5x de 21,99",
            buy: "https://wa.link/2rbjyq"
        },
        {
            id: 71,
            name: "pulseira",
            image: require('../assets/produtos/pulseira-bolas-g.jpeg'),
            image2: require('../assets/produtos/pulseira-bolas-g2.jpeg'),
            image3: require('../assets/produtos/pulseira-bolas-g2.jpeg'),
            title: "PULSEIRA DE BOLAS",
            price: "99,99",
            priceNow: "R$ 87,99",
            overview: "Pulseira Prata de Bolas - 19cm. Adicione um toque de elegância ao seu visual com essa deslumbrante pulseira! Feita com materiais de alta qualidade e com um design sofisticado, ela irá complementar perfeitamente qualquer roupa.",
            parcel: "5x de 17,60",
            buy: "https://wa.link/fs8usf"
        },
        {
            id: 72,
            name: "pulseira",
            image: require('../assets/produtos/pulseira-retalha-fina.jpeg'),
            image2: require('../assets/produtos/pulseira-retalha-fino2.jpeg'),
            image3: require('../assets/produtos/pulseira-retalha-fino2.jpeg'),
            title: "PULSEIRA RETALHADA",
            price: "84,99",
            priceNow: "R$ 74,99",
            overview: "Pulseira Prata Retalho Fino - 19cm. Transforme o seu estilo em algo ainda mais marcante com a pulseira perfeita! Não há melhor maneira de expressar sua individualidade e adicionar um toque pessoal ao seu look do que com uma pulseira única.",
            parcel: "5x de 14,99",
            buy: "https://wa.link/7z3etb"
        },
        {
            id: 73,
            name: "brinco",
            image: require('../assets/produtos/brinco-duplinha-estrela-lua.jpeg'),
            image2: require('../assets/produtos/brinco-duplinha-estrela-lua.jpeg'),
            image3: require('../assets/produtos/brinco-duplinha-estrela-lua.jpeg'),
            title: "BRINCO DUPLINHA",
            price: "45,99",
            priceNow: "R$ 35,99",
            overview: "Brinco Prata Duplinha Estrela e Lua. O brinco é o acessório perfeito para dar um toque de elegância e sofisticação a qualquer visual. Seja para uma ocasião especial ou para o dia a dia, um brinco bem escolhido pode transformar completamente o seu look.",
            parcel: "5x de 7,20",
            buy: "https://wa.link/50kvz9"
        },
        {
            id: 74,
            name: "brinco",
            image: require('../assets/produtos/brinco-ponto-luz-estrela.jpeg'),
            image2: require('../assets/produtos/brinco-ponto-luz-estrela.jpeg'),
            image3: require('../assets/produtos/brinco-ponto-luz-estrela.jpeg'),
            title: "BRINCO PONTO DE LUZ ESTRELA",
            price: "79,99",
            priceNow: "R$ 69,99",
            overview: "Brinco Ponto de Luz Estrela - 4 e 5mm. Não perca a chance de destacar a sua beleza com um brinco incrível. Adquira já o seu e sinta-se ainda mais deslumbrante.",
            parcel: "5x de 13,99",
            buy: "https://wa.link/ojm15c"
        },
        {
            id: 75,
            name: "brinco",
            image: require('../assets/produtos/brinco-ponto-luz-redondo.jpeg'),
            image2: require('../assets/produtos/brinco-ponto-luz-redondo.jpeg'),
            image3: require('../assets/produtos/brinco-ponto-luz-redondo.jpeg'),
            title: "BRINCO PONTO DE LUZ REDONDO",
            price: "55,99",
            priceNow: "R$ 43,99",
            overview: "Brinco Duplinha Ponto de Luz Redondo - 5 e 7mm. Os brincos são acessórios que fazem toda a diferença em um look. Eles são capazes de transformar uma roupa simples em um visual elegante e sofisticado.",
            parcel: "5x de 8,80",
            buy: "https://wa.link/95hwyy"
        },
        {
            id: 76,
            name: "brinco",
            image: require('../assets/produtos/brinco-ponto-luz-quadrado.jpeg'),
            image2: require('../assets/produtos/brinco-ponto-luz-quadrado.jpeg'),
            image3: require('../assets/produtos/brinco-ponto-luz-quadrado.jpeg'),
            title: "BRINCO PONTO DE LUZ QUADRADO",
            price: "84,99",
            priceNow: "R$ 74,99",
            overview: "Brinco Duplinha Ponto de Luz Quadrado - 6 e 7mm. Com um brinco de qualidade, você pode realçar ainda mais a sua beleza natural e chamar a atenção por onde passar. Invista em um par de brincos excepcionais agora mesmo e se sinta ainda mais confiante e poderosa",
            parcel: "5x de 14,99",
            buy: "https://wa.link/sihvlx"
        },
        {
            id: 77,
            name: "brinco",
            image: require('../assets/produtos/argola-1g.jpeg'),
            image2: require('../assets/produtos/argola-1g.jpeg'),
            image3: require('../assets/produtos/argola-1g.jpeg'),
            title: "ARGOLA",
            price: "59,99",
            priceNow: "R$ 47,99",
            overview: "Argola 1G. As argolas são clássicos atemporais que nunca saem de moda. Elas são acessórios versáteis e elegantes, capazes de complementar qualquer look com um toque de sofisticação e estilo. . Não perca mais tempo e adquira já uma argola deslumbrante para fazer a diferença em seu visual.",
            parcel: "5x de 9,60",
            buy: "https://wa.link/waewks"
        },
        {
            id: 78,
            name: "tornozeleira",
            image: require('../assets/produtos/tornozeleira-estrela-do-mar.jpeg'),
            image2: require('../assets/produtos/tornozeleira-estrela-do-mar.jpeg'),
            image3: require('../assets/produtos/tornozeleira-estrela-do-mar.jpeg'),
            title: "TORNOZELEIRA ESTRELA DO MAR",
            price: "77,99",
            priceNow: "ESGOTADO",
            overview: "Tornozeleira Elo Coração Estrela do Mar - 30cm. As tornozeleiras são perfeitas para destacar suas pernas e adicionar um toque de feminilidade à sua aparência. Adquira agora uma tornozeleira encantadora e sinta-se ainda mais confiante e estilosa em todos os momentos.",
            parcel: "5x de 13,60",
            buy: "https://wa.link/djkats"
        },
        {
            id: 79,
            name: "tornozeleira",
            image: require('../assets/produtos/tornozeleira-ponto-de-luz.jpeg'),
            image2: require('../assets/produtos/tornozeleira-ponto-de-luz.jpeg'),
            image3: require('../assets/produtos/tornozeleira-ponto-de-luz.jpeg'),
            title: "TORNOZELEIRA CORAÇÃO",
            price: "77,99",
            priceNow: "ESGOTADO",
            overview: "Tornozeleira Elo Coração com Ponto de Luz - 28cm. Tornozeleiras são acessórios que trazem um charme extra para qualquer look, além de serem a escolha perfeita para quem busca uma maneira sutil de destacar as pernas. não perca mais tempo e adquira agora uma tornozeleira incrível para complementar seu estilo.",
            parcel: "5x de 13,60",
            buy: "https://wa.link/nho2n3"
        },
        {
            id: 80,
            name: "tornozeleira",
            image: require('../assets/produtos/tornozeleira-borboleta.jpeg'),
            image2: require('../assets/produtos/tornozeleira-borboleta.jpeg'),
            image3: require('../assets/produtos/tornozeleira-borboleta.jpeg'),
            title: "TORNOZELEIRA BORBOLETA",
            price: "77,99",
            priceNow: "ESGOTADO",
            overview: "Tornozeleira Borboleta Colorida - 26cm. Um acessório delicado e charmoso, que pode fazer toda a diferença em seu visual. Ela é perfeita para quem busca um toque de elegância e descontração, sem abrir mão do estilo.",
            parcel: "5x de 13,60",
            buy: "https://wa.link/4i0oxl"
        },
        {
            id: 81,
            name: "masculino",
            image: require('../assets/produtos/corrente-masc-8.2gr.jpeg'),
            image2: require('../assets/tamanhos-colar.webp'),
            image3: require('../assets/produtos/corrente-masc-8.2gr.jpeg'),
            title: "CORRENTE MASCULINA",
            price: "219,99",
            priceNow: "R$ 195,99",
            overview: "Corrente Masculina 8.2GR - 60cm. Uma corrente masculina é muito mais do que apenas um acessório. Ela é uma afirmação de estilo e personalidade, que pode destacar ainda mais a beleza e a força do homem moderno. Invista agora em uma corrente masculina de qualidade e sinta a diferença em seu visual e em sua autoconfiança.",
            parcel: "5x de 39,20",
            buy: "https://wa.link/61290k"
        },*/
        {
            id: 82,
            name: "escapulario",
            image: require('../assets/produtos/escap-masc.jpeg'),
            image2: require('../assets/tamanhos-colar.webp'),
            image3: require('../assets/produtos/escap-masc.jpeg'),
            title: "ESCAPULÁRIO MASCULINO",
            price: "179,99",
            priceNow: "R$ 164,99",
            overview: "Escapulário Masculino - 40 a 45cm. O escapulário masculino é um símbolo de fé e devoção que transcende as barreiras religiosas. Além disso, é um item de estilo marcante. Adquira agora o seu escapulário masculino e sinta a força e a proteção que ele pode trazer para a sua vida.",
            parcel: "5x de 32,99",
            buy: "https://wa.link/nn275f"
        },
        /*{
            id: 83,
            name: "infantil",
            image: require('../assets/produtos/pulseira-infantil-placa.jpeg'),
            image2: require('../assets/produtos/pulseira-infantil-placa2.jpeg'),
            image3: require('../assets/produtos/pulseira-infantil-placa.jpeg'),
            title: "PULSEIRA INFANTIL",
            price: "79,99",
            priceNow: "R$ 65,99",
            overview: "Pulseira Infantil Placa Para Gravar - 15cm. Uma pulseira infantil pode ser um presente muito especial para as crianças, pois além de ser um acessório encantador, ela pode ajudá-las a desenvolver sua própria identidade e estilo pessoal. Adquira agora uma pulseira infantil encantadora e faça a alegria das crianças em sua vida.",
            parcel: "5x de 13,20",
            buy: "https://wa.link/559hym"
        },
        {
            id: 84,
            name: "infantil",
            image: require('../assets/produtos/pulsiera-infantil-ponto-de-luz.jpeg'),
            image2: require('../assets/produtos/pulsiera-infantil-ponto-de-luz2.jpeg'),
            image3: require('../assets/produtos/pulsiera-infantil-ponto-de-luz.jpeg'),
            title: "PULSEIRA INFANTIL",
            price: "59,99",
            priceNow: "R$ 40,99",
            overview: "Pulseira Infantil Ponto de Luz Azul - 14.5cm. Uma pulseira infantil é um presente que pode marcar a infância de uma criança para sempre, é um acessório delicado e charmoso, que pode ser usado em diversas ocasiões especiais. Escolha agora uma pulseira infantil encantadora para presentear uma criança especial e tornar esse momento ainda mais inesquecível.",
            parcel: "5x de 8,20",
            buy: "https://wa.link/fjsw2n"
        },
        {
            id: 85,
            name: "infantil",
            image: require('../assets/produtos/pulseira-infantil-ponto-vermelho.jpeg'),
            image2: require('../assets/produtos/pulseira-infantil-ponto-vermelho2.jpeg'),
            image3: require('../assets/produtos/pulseira-infantil-ponto-vermelho.jpeg'),
            title: "PULSEIRA INFANTIL",
            price: "49,99",
            priceNow: "R$ 37,99",
            overview: "Pulseira Infantil Ponto de Luz Vermelho - 13.5cm. As crianças adoram acessórios divertidos e coloridos, e uma pulseira infantil pode ser o presente perfeito para deixar o seu filho ou filha ainda mais feliz.  Adquira agora uma pulseira infantil de qualidade e faça o dia do seu filho ou filha ainda mais especial.",
            parcel: "5x de 7,60",
            buy: "https://wa.link/50x7kp"
        },
        /*{
            id: 86,
            name: "colar",
            image: require('../assets/produtos/conjunto-ponto-de-luz.jpeg'),
            image2: require('../assets/produtos/conjunto-ponto-de-luz2.jpeg'),
            image3: require('../assets/tamanhos-colar.webp'),
            title: "CONJUNTO PONTO DE LUZ",
            price: "109,99",
            priceNow: "R$ 93,99",
            overview: "Conjunto Ponto de Luz - 40cm. Complemente o seu look com elegância e sofisticação com este conjunto deslumbrante. Feito com materiais de alta qualidade e com um design exclusivo, este conjunto certamente irá chamar a atenção e realçar a sua beleza natural.",
            parcel: "5x de 18,80",
            buy: "https://wa.link/lljdmr"
        },
        {
            id: 88,
            name: "brinco",
            image: require('../assets/produtos/trio-argolas.jpeg'),
            image2: require('../assets/produtos/trio-argolas2.jpeg'),
            image3: require('../assets/produtos/trio-argolas.jpeg'),
            title: "TRIO ARGOLAS",
            price: "59,99",
            priceNow: "R$ 47,99",
            overview: "Trio de Argolas - 1,3mm / 1,5mm / 1,7mm. Adicione um toque de glamour e versatilidade ao seu estilo com o trio de argolas que vai revolucionar seu visual. Não perca a oportunidade de elevar seu estilo com essas joias deslumbrantes que vão roubar a cena onde quer que você vá.",
            parcel: "5x de 9,60",
            buy: "https://wa.link/bz79gf"
        },
        {
            id: 89,
            name: "pulseira",
            image: require('../assets/produtos/pulseira-elos-coracao-pequeno.jpeg'),
            image2: require('../assets/produtos/pulseira-elos-coracao-pequeno2.jpeg'),
            image3: require('../assets/produtos/pulseira-elos-coracao-pequeno.jpeg'),
            title: "PULSEIRA ELOS CORAÇÃO",
            price: "49,99",
            priceNow: "R$ 31,99",
            overview: "Pulseira Elo Coração - 18cm. Descubra a magia que envolve esta pulseira encantadora. Seu brilho radiante e seu design exuberante transformam qualquer look em uma verdadeira obra de arte. Não espere mais, adquira sua pulseira e permita-se brilhar em todos os momentos.",
            parcel: "5x de 6,40",
            buy: "https://wa.link/482o4g"
        },
        {
            id: 90,
            name: "colar",
            image: require('../assets/produtos/colar-coracao-vazado.jpeg'),
            image2: require('../assets/produtos/colar-coracao-vazado2.jpeg'),
            image3: require('../assets/produtos/colar-coracao-vazado.jpeg'),
            title: "COLAR CORAÇÃO VAZADO",
            price: "79,99",
            priceNow: "R$ 67,99",
            overview: "Colar Coração Vazado - 40cm. Um colar que vai encantar seus sentidos e iluminar sua beleza. Não perca tempo, pois a elegância espera por você, adquira agora esse deslumbrante colar, feito com os mais preciosos materiais e adornado com detalhes brilhantes.",
            parcel: "5x de 13,60",
            buy: "https://wa.link/ju49cn"
        },*/
        {
            id: 91,
            name: "masculino",
            image: require('../assets/produtos/corrente-groumett.jpeg'),
            image2: require('../assets/produtos/corrente-groumett2.jpeg'),
            image3: require('../assets/produtos/corrente-elo-groumett-3.jpeg'),
            title: "CORRENTE MASCULINA",
            price: "189,99",
            priceNow: "R$ 166,99",
            overview: "Corrente Groumett - 70cm - 7gr. Seja um verdadeiro ícone de estilo masculino com esta corrente de prata que vai elevar sua aparência a um novo patamar de sofisticação. Feita de prata genuína, cada elo brilha com um acabamento impecável, transmitindo durabilidade e qualidade excepcionais.",
            parcel: "5x de 33,40",
            buy: "https://wa.link/uj6acf"
        },
        {
            id: 92,
            name: "masculino",
            image: require('../assets/produtos/pulseira-elo-groumett-3.jpeg'),
            image2: require('../assets/produtos/pulseira-elo-grumett.jpeg'),
            image3: require('../assets/produtos/pulseira-elo-grumett2.jpeg'),
            title: "PULSEIRA ELO GROUMETT",
            price: "69,99",
            priceNow: "R$ 59,99",
            overview: "Pulseira Elo Groumett 19.5cm. Adicione um toque de brilho e estilo ao seu pulso com esta deslumbrante pulseira. Cada elo cuidadosamente trabalhado reflete a beleza atemporal e a elegância sofisticada que você merece.",
            parcel: "5x de 12,00",
            buy: "https://wa.link/sodvha"
        },
        /*{
            id: 93,
            name: "masculino",
            image: require('../assets/produtos/pulseira-piastrini2.jpeg'),
            image2: require('../assets/produtos/pulseira-piastrini.jpeg'),
            image3: require('../assets/produtos/pulseira-piastrini2.jpeg'),
            title: "PULSEIRA ELO PIASTRINI",
            price: "49,99",
            priceNow: "ESGOTADO",
            overview: "Pulseira Elo Piastrini 1,1gr. Sinta a confiança e a autoexpressão elevarem-se enquanto você exibe essa pulseira única em seu pulso. Aproveite a oportunidade de possuir uma peça exclusiva que será admirada por sua beleza e delicadeza em todos os momentos.",
            parcel: "5x de 6,99",
            buy: "https://wa.link/haejfi"
        },
        {
            id: 94,
            name: "brinco",
            image: require('../assets/produtos/brinco-coracao-vazado.jpeg'),
            image2: require('../assets/produtos/brinco-coracao-vazado2.jpeg'),
            image3: require('../assets/produtos/brinco-coracao-vazado.jpeg'),
            title: "BRINCO CORAÇÃO VAZADO",
            price: "79,99",
            priceNow: "R$ 63,99",
            overview: "Brinco Coração Vazado - 2,2 x 1,7cm. Descubra o poder de um detalhe elegante! Adquira agora mesmo nosso deslumbrante brinco, perfeito para realçar sua beleza e transmitir sua personalidade única. Seja o centro das atenções e encante a todos com esse acessório sofisticado.",
            parcel: "5x de 12,80",
            buy: "https://wa.link/apq5l9"
        },*/
        {
            id: 95,
            name: "brinco",
            image: require('../assets/produtos/brinco-retangular.jpeg'),
            image2: require('../assets/produtos/brinco-retangular2.jpeg'),
            image3: require('../assets/produtos/brinco-retangular.jpeg'),
            title: "BRINCO RETANGULAR",
            price: "89,99",
            priceNow: "R$ 77,99",
            overview: "Brinco Retangular Vazado 1 x 3,2cm. Realce sua beleza com o brinco perfeito! Adicione um toque de elegância e sofisticação ao seu visual com nossos brincos deslumbrantes. Brilhe como nunca antes e deixe seu estilo falar por si só.",
            parcel: "5x de 15,60",
            buy: "https://wa.link/8fastd"
        },
        {
            id: 96,
            name: "brinco",
            image: require('../assets/produtos/brinco-2-elos-vazados.jpeg'),
            image2: require('../assets/produtos/brinco-2-elos-vazados.jpeg'),
            image3: require('../assets/produtos/brinco-2-elos-vazados.jpeg'),
            title: "BRINCO 2 ELOS VAZADOS",
            price: "78,99",
            priceNow: "R$ 65,99",
            overview: "Brinco 2 Elos Vazados - 8mm x 3cm. Dê um toque de elegância ao seu visual com nosso incrível brinco! Seja o centro das atenções e brilhe com estilo em qualquer ocasião. Com design sofisticado e materiais de alta qualidade, esse brinco é a escolha perfeita para mulheres modernas e cheias de personalidade.",
            parcel: "5x de 13,20",
            buy: "https://wa.link/nlxr09"
        },
        {
            id: 97,
            name: "brinco",
            image: require('../assets/produtos/brinco-coracao-vazado-peq.jpeg'),
            image2: require('../assets/produtos/brinco-coracao-vazado-peq.jpeg'),
            image3: require('../assets/produtos/brinco-coracao-vazado-peq.jpeg'),
            title: "BRINCO CORAÇÃO VAZADO",
            price: "59,99",
            priceNow: "R$ 42,99",
            overview: "Brinco Coração Vazado - 8mm. Adorne-se com elegância e brilho! Descubra o poder transformador de um brinco deslumbrante que elevará seu estilo a novas alturas. Com design exuberante e materiais de alta qualidade, nosso brinco é uma obra-prima que irá encantar seus sentidos.",
            parcel: "5x de 8,60",
            buy: "https://wa.link/m7w7ws"
        },
        {
            id: 98,
            name: "brinco",
            image: require('../assets/produtos/brinco-corrente.jpeg'),
            image2: require('../assets/produtos/brinco-corrente.jpeg'),
            image3: require('../assets/produtos/brinco-corrente.jpeg'),
            title: "BRINCO CORRENTE",
            price: "79,99",
            priceNow: "R$ 66,99",
            overview: "Brinco Corrente com Ponto de Luz Coração. Não espere mais para adquirir essa joia exclusiva que irá iluminar seu rosto e sua vida. Eleve seu visual, exalte sua beleza e faça uma declaração de sofisticação com nosso brinco extraordinário. ",
            parcel: "5x de 13,60",
            buy: 'https://wa.link/w9wpxc'
        },
        {
            id: 99,
            name: "masculino",
            image: require('../assets/produtos/corrente-elo-tijolinho1.jpeg'),
            image2: require('../assets/produtos/corrente-elo-tijolinho2.jpeg'),
            image3: require('../assets/produtos/corrente-elo-tijolinho1.jpeg'),
            title: "CORRENTE ELO TIJOLINHO",
            price: "119,99",
            priceNow: "R$ 104,99",
            overview: "Corrente Elo Tijolinho - 60cm. Seu estilo merece a autenticidade desta corrente masculina. Feita para os que valorizam o poder da sofisticação despretensiosa. Cada elo conta uma história de força e masculinidade. Seja para aprimorar seu visual casual ou elevar seu charme formal, esta corrente é mais do que um acessório.",
            parcel: "5x de 21,00",
            buy: "https://wa.link/8r75j6"
        },
        {
            id: 100,
            name: "masculino",
            image: require('../assets/produtos/pulseira-3x1.jpeg'),
            image2: require('../assets/produtos/pulseira-3x1.2.jpeg'),
            image3: require('../assets/produtos/pulseira-3x1.jpeg'),
            title: "PULSEIRA MASCULINA",
            price: "112,99",
            priceNow: "R$ 101,99",
            overview: "Pulseira Masculina 20cm. Na sua jornada rumo à excelência no estilo, cada detalhe importa. Esta pulseira masculina não é apenas um acessório, é a expressão da sua individualidade e bom gosto. Combinando perfeitamente com qualquer visual, do casual ao sofisticado, ela irá enaltecer o seu pulso de uma forma única.",
            parcel: "5x de 20,40",
            buy: "https://wa.link/p72etw"
        },
        {
            id: 101,
            name: "masculino",
            image: require('../assets/produtos/pulseira-groumett-5.jpeg'),
            image2: require('../assets/produtos/pulseira-groummet-5-2.jpeg'),
            image3: require('../assets/produtos/pulseira-groumett-5.jpeg'),
            title: "PULSEIRA MASCULINA",
            price: "199,99",
            priceNow: "R$ 174,99",
            overview: "Pulseira Elo Groumett - 5mm. Eleve seu estilo com a sofisticação e a masculinidade que uma pulseira única proporciona. Uma declaração de personalidade e elegância, esta pulseira é o toque final que seu visual merece.",
            parcel: "5x de 34,99",
            buy: "https://wa.link/4po3l5",
        },
        /* {
            id: 102,
            name: "masculino",
            image: require('../assets/produtos/pulseira-groumett-6.jpeg'),
            image2: require('../assets/produtos/pulseira-groumett-6.2.jpeg'),
            image3: require('../assets/produtos/pulseira-groumett-6.jpeg'),
            title: "PULSEIRA MASCULINA",
            price: "229,99",
            priceNow: "R$ 203,99",
            overview: "Pulseira Elo Groumett - 6mm. Eleve o seu estilo com a sofisticação da masculina pulseira que combina perfeitamente com qualquer ocasião. Um toque de elegância e confiança ao alcance do seu pulso.",
            parcel: "5x de 40,80",
            buy: "https://wa.link/oaz5x6"
        }, */
        {
            id: 103,
            name: "masculino",
            image: require('../assets/produtos/pulseira-groumett-7.jpeg'),
            image2: require('../assets/produtos/pulseira-groumett-7-2.jpeg'),
            image3: require('../assets/produtos/pulseira-groumett-7.jpeg'),
            title: "PULSEIRA MASCULINA",
            price: "319,99",
            priceNow: "R$ 283,99",
            overview: "Pulseira Elo Groumett - 7mm. Eleve seu estilo com a sofisticação ousada da nossa pulseira masculina. Feita para os que buscam mais do que simples acessórios, esta pulseira é um símbolo de poder e elegância. Faça uma declaração de moda única e destaque-se em qualquer ocasião com nossa pulseira que combina perfeitamente com a sua personalidade distintiva.",
            parcel: "5x de 56,80",
            buy: "https://wa.link/lcqpqs"
        },/*
        {
            id: 104,
            name: "colar",
            image: require('../assets/produtos/colar-coracoes1.jpeg'),
            image2: require('../assets/produtos/colar-coracoes2.jpeg'),
            image3: require('../assets/produtos/colar-coracoes1.jpeg'),
            title: "COLAR CORAÇÕES",
            price: "169,99",
            priceNow: "R$ 142,99",
            overview: "Colar Corações - 40cm. Gargantilha com 13 corações. Embrace o poder do amor com cada batida do seu coração adornado por este colar de corações. Cada coração representa uma história, um momento e um sentimento, criando um elo eterno de beleza e emoção.",
            parcel: "5x de 28,60",
            buy: "https://wa.link/8ejkrw"
        },
        {
            id: 105,
            name: "colar",
            image: require('../assets/produtos/choker-bolas-grandes.jpeg'),
            image2: require('../assets/produtos/choker-bolas-grandes2.jpeg'),
            image3: require('../assets/produtos/choker-bolas-grandes2.jpeg'),
            title: "CHOCKER FEMININO",
            price: "199,99",
            priceNow: "R$ 174,99",
            overview: "Chocker Bolas Grandes e Pequenas - 40cm. Gargantilha com 15 bloas maciças. Transforme seu estilo em uma declaração ousada com este colar deslumbrante. Cada detalhe brilha como uma promessa de elegância e sofisticação. Use-o como um símbolo do seu próprio brilho interior ou ofereça-o a alguém especial, envolvendo-os em beleza incomparável.",
            parcel: "5x de 34,99",
            buy: "https://wa.link/eft98v"
        },
        {
            id: 106,
            name: "colar",
            image: require('../assets/produtos/gargantilha-aro.jpeg'),
            image2: require('../assets/produtos/gargantilha-aro2.jpeg'),
            image3: require('../assets/produtos/gargantilha-aro.jpeg'),
            title: "GARGANTILHA ARO",
            price: "219,99",
            priceNow: "R$ 194,99",
            overview: "Gargantilha Aro. Realce sua elegância com a ousadia desta gargantilha única. Cada curva e detalhe foram criados para capturar olhares e despertar admiração. Vista-a para acrescentar um toque de mistério e sofisticação ao seu visual, seja numa noite especial ou no seu dia a dia.",
            parcel: "5x de 38,99",
            buy: "https://wa.link/yl3opr"
        },*/
        {
            id: 107,
            name: "colar",
            image: require('../assets/produtos/colar-coracao-grande-vazado1.jpeg'),
            image2: require('../assets/produtos/colar-coracao-grande-vazado2.jpeg'),
            image3: require('../assets/produtos/colar-coracao-grande-vazado.jpeg'),
            title: "COLAR CORAÇÃO VAZADO",
            price: "103,99",
            priceNow: "R$ 93,99",
            overview: "Colar Coração grande vazado - 45cm. Adoce o seu estilo com o colar dos sonhos. Este não é apenas um acessório, é uma declaração de elegância e personalidade. Feito com detalhes cuidadosamente trabalhados, este colar é a combinação perfeita entre moda e sofisticação!.",
            parcel: "5x de 18,80",
            buy: "https://wa.link/poqa4d"
        },
        /*{
            id: 108,
            name: "colar",
            image: require('../assets/produtos/colar-singapura-A.jpeg'),
            image2: require('../assets/produtos/colar-singapura-A.jpeg'),
            image3: require('../assets/produtos/colar-singapura-A.jpeg'),
            title: "COLAR SINGAPURA A",
            price: "99,99",
            priceNow: "R$ 87,99",
            overview: "Colar Singapura com pedras de zircônia - 45cm. Adicione um toque pessoal à sua moda com este deslumbrante colar com pingente da letra A. Não é apenas uma peça de joia, mas uma declaração de estilo única para você. Mostre ao mundo sua individualidade e elegância com essa inicial que representa tanto o seu nome quanto a sua personalidade.",
            parcel: "4x de 21,99",
            buy: "https://wa.link/glf5ku"
        },
        {
            id: 109,
            name: "colar",
            image: require('../assets/produtos/colar-singapura-B.jpeg'),
            image2: require('../assets/produtos/colar-singapura-B.jpeg'),
            image3: require('../assets/produtos/colar-singapura-B.jpeg'),
            title: "COLAR SINGAPURA B",
            price: "99,99",
            priceNow: "R$ 87,99",
            overview: "Colar Singapura com pedras de zircônia - 45cm. Adicione um toque pessoal à sua moda com este deslumbrante colar com pingente da letra B. Não é apenas uma peça de joia, mas uma declaração de estilo única para você. Mostre ao mundo sua individualidade e elegância com essa inicial que representa tanto o seu nome quanto a sua personalidade.",
            parcel: "4x de 21,99",
            buy: "https://wa.link/b0y7z2"
        },
        {
            id: 110,
            name: "colar",
            image: require('../assets/produtos/colar-singapura-C.jpeg'),
            image2: require('../assets/produtos/colar-singapura-C.jpeg'),
            image3: require('../assets/produtos/colar-singapura-C.jpeg'),
            title: "COLAR SINGAPURA C",
            price: "99,99",
            priceNow: "R$ 87,99",
            overview: "Colar Singapura com pedras de zircônia - 45cm. Adicione um toque pessoal à sua moda com este deslumbrante colar com pingente da letra C. Não é apenas uma peça de joia, mas uma declaração de estilo única para você. Mostre ao mundo sua individualidade e elegância com essa inicial que representa tanto o seu nome quanto a sua personalidade.",
            parcel: "4x de 21,99",
            buy: "https://wa.link/m4x0du"
        },*/
        {
            id: 111,
            name: "colar",
            image: require('../assets/produtos/colar-singapura-D.jpeg'),
            image2: require('../assets/produtos/colar-singapura-D.jpeg'),
            image3: require('../assets/produtos/colar-singapura-D.jpeg'),
            title: "COLAR SINGAPURA D",
            price: "99,99",
            priceNow: "R$ 87,99",
            overview: "Colar Singapura com pedras de zircônia - 45cm. Adicione um toque pessoal à sua moda com este deslumbrante colar com pingente da letra D. Não é apenas uma peça de joia, mas uma declaração de estilo única para você. Mostre ao mundo sua individualidade e elegância com essa inicial que representa tanto o seu nome quanto a sua personalidade.",
            parcel: "4x de 21,99",
            buy: "https://wa.link/50oz2b"
        },
        /*{
            id: 112,
            name: "colar",
            image: require('../assets/produtos/colar-singapura-E.jpeg'),
            image2: require('../assets/produtos/colar-singapura-E.jpeg'),
            image3: require('../assets/produtos/colar-singapura-E.jpeg'),
            title: "COLAR SINGAPURA E",
            price: "99,99",
            priceNow: "R$ 87,99",
            overview: "Colar Singapura com pedras de zircônia - 45cm. Adicione um toque pessoal à sua moda com este deslumbrante colar com pingente da letra E. Não é apenas uma peça de joia, mas uma declaração de estilo única para você. Mostre ao mundo sua individualidade e elegância com essa inicial que representa tanto o seu nome quanto a sua personalidade.",
            parcel: "4x de 21,99",
            buy: "https://wa.link/q3gjiq"
        },
        {
            id: 113,
            name: "colar",
            image: require('../assets/produtos/colar-singapura-F.jpeg'),
            image2: require('../assets/produtos/colar-singapura-F.jpeg'),
            image3: require('../assets/produtos/colar-singapura-F.jpeg'),
            title: "COLAR SINGAPURA F",
            price: "99,99",
            priceNow: "R$ 87,99",
            overview: "Colar Singapura com pedras de zircônia - 45cm. Adicione um toque pessoal à sua moda com este deslumbrante colar com pingente da letra F. Não é apenas uma peça de joia, mas uma declaração de estilo única para você. Mostre ao mundo sua individualidade e elegância com essa inicial que representa tanto o seu nome quanto a sua personalidade.",
            parcel: "4x de 21,99",
            buy: "https://wa.link/dfh3vc"
        },
        {
            id: 114,
            name: "colar",
            image: require('../assets/produtos/colar-singapura-G.jpeg'),
            image2: require('../assets/produtos/colar-singapura-G.jpeg'),
            image3: require('../assets/produtos/colar-singapura-G.jpeg'),
            title: "COLAR SINGAPURA G",
            price: "99,99",
            priceNow: "R$ 87,99",
            overview: "Colar Singapura com pedras de zircônia - 45cm. Adicione um toque pessoal à sua moda com este deslumbrante colar com pingente da letra G. Não é apenas uma peça de joia, mas uma declaração de estilo única para você. Mostre ao mundo sua individualidade e elegância com essa inicial que representa tanto o seu nome quanto a sua personalidade.",
            parcel: "4x de 21,99",
            buy: "https://wa.link/m72fmm"
        },
        {
            id: 115,
            name: "colar",
            image: require('../assets/produtos/colar-singapura-H.jpeg'),
            image2: require('../assets/produtos/colar-singapura-H.jpeg'),
            image3: require('../assets/produtos/colar-singapura-H.jpeg'),
            title: "COLAR SINGAPURA H",
            price: "99,99",
            priceNow: "R$ 87,99",
            overview: "Colar Singapura com pedras de zircônia - 45cm. Adicione um toque pessoal à sua moda com este deslumbrante colar com pingente da letra H. Não é apenas uma peça de joia, mas uma declaração de estilo única para você. Mostre ao mundo sua individualidade e elegância com essa inicial que representa tanto o seu nome quanto a sua personalidade.",
            parcel: "4x de 21,99",
            buy: "https://wa.link/zfhoxi"
        },
        {
            id: 116,
            name: "colar",
            image: require('../assets/produtos/colar-singapura-I.jpeg'),
            image2: require('../assets/produtos/colar-singapura-I.jpeg'),
            image3: require('../assets/produtos/colar-singapura-I.jpeg'),
            title: "COLAR SINGAPURA I",
            price: "99,99",
            priceNow: "R$ 87,99",
            overview: "Colar Singapura com pedras de zircônia - 45cm. Adicione um toque pessoal à sua moda com este deslumbrante colar com pingente da letra I. Não é apenas uma peça de joia, mas uma declaração de estilo única para você. Mostre ao mundo sua individualidade e elegância com essa inicial que representa tanto o seu nome quanto a sua personalidade.",
            parcel: "4x de 21,99",
            buy: "https://wa.link/bcvkbb"
        },*/
        {
            id: 117,
            name: "colar",
            image: require('../assets/produtos/colar-singapura-J.jpeg'),
            image2: require('../assets/produtos/colar-singapura-J.jpeg'),
            image3: require('../assets/produtos/colar-singapura-J.jpeg'),
            title: "COLAR SINGAPURA J",
            price: "99,99",
            priceNow: "R$ 87,99",
            overview: "Colar Singapura com pedras de zircônia - 45cm. Adicione um toque pessoal à sua moda com este deslumbrante colar com pingente da letra J. Não é apenas uma peça de joia, mas uma declaração de estilo única para você. Mostre ao mundo sua individualidade e elegância com essa inicial que representa tanto o seu nome quanto a sua personalidade.",
            parcel: "4x de 21,99",
            buy: "https://wa.link/1p0yrv"
        },
        {
            id: 118,
            name: "colar",
            image: require('../assets/produtos/colar-singapura-K.jpeg'),
            image2: require('../assets/produtos/colar-singapura-K.jpeg'),
            image3: require('../assets/produtos/colar-singapura-K.jpeg'),
            title: "COLAR SINGAPURA K",
            price: "99,99",
            priceNow: "R$ 87,99",
            overview: "Colar Singapura com pedras de zircônia - 45cm. Adicione um toque pessoal à sua moda com este deslumbrante colar com pingente da letra K. Não é apenas uma peça de joia, mas uma declaração de estilo única para você. Mostre ao mundo sua individualidade e elegância com essa inicial que representa tanto o seu nome quanto a sua personalidade.",
            parcel: "4x de 21,99",
            buy: "https://wa.link/gzjuug"
        },
        /*{
            id: 119,
            name: "colar",
            image: require('../assets/produtos/colar-singapura-L.jpeg'),
            image2: require('../assets/produtos/colar-singapura-L.jpeg'),
            image3: require('../assets/produtos/colar-singapura-L.jpeg'),
            title: "COLAR SINGAPURA L",
            price: "99,99",
            priceNow: "R$ 87,99",
            overview: "Colar Singapura com pedras de zircônia - 45cm. Adicione um toque pessoal à sua moda com este deslumbrante colar com pingente da letra L. Não é apenas uma peça de joia, mas uma declaração de estilo única para você. Mostre ao mundo sua individualidade e elegância com essa inicial que representa tanto o seu nome quanto a sua personalidade.",
            parcel: "4x de 21,99",
            buy: "https://wa.link/918a3k"
        },
        {
            id: 120,
            name: "colar",
            image: require('../assets/produtos/colar-singapura-M.jpeg'),
            image2: require('../assets/produtos/colar-singapura-M.jpeg'),
            image3: require('../assets/produtos/colar-singapura-M.jpeg'),
            title: "COLAR SINGAPURA M",
            price: "99,99",
            priceNow: "R$ 87,99",
            overview: "Colar Singapura com pedras de zircônia - 45cm. Adicione um toque pessoal à sua moda com este deslumbrante colar com pingente da letra M. Não é apenas uma peça de joia, mas uma declaração de estilo única para você. Mostre ao mundo sua individualidade e elegância com essa inicial que representa tanto o seu nome quanto a sua personalidade.",
            parcel: "4x de 21,99",
            buy: "https://wa.link/wie0xs"
        },*/
        {
            id: 121,
            name: "colar",
            image: require('../assets/produtos/colar-singapura-O.jpeg'),
            image2: require('../assets/produtos/colar-singapura-O.jpeg'),
            image3: require('../assets/produtos/colar-singapura-O.jpeg'),
            title: "COLAR SINGAPURA O",
            price: "99,99",
            priceNow: "R$ 87,99",
            overview: "Colar Singapura com pedras de zircônia - 45cm. Adicione um toque pessoal à sua moda com este deslumbrante colar com pingente da letra O. Não é apenas uma peça de joia, mas uma declaração de estilo única para você. Mostre ao mundo sua individualidade e elegância com essa inicial que representa tanto o seu nome quanto a sua personalidade.",
            parcel: "4x de 21,99",
            buy: "https://wa.link/wzul1n"
        },
        {
            id: 122,
            name: "colar",
            image: require('../assets/produtos/colar-singapura-P.jpeg'),
            image2: require('../assets/produtos/colar-singapura-P.jpeg'),
            image3: require('../assets/produtos/colar-singapura-P.jpeg'),
            title: "COLAR SINGAPURA P",
            price: "99,99",
            priceNow: "R$ 87,99",
            overview: "Colar Singapura com pedras de zircônia - 45cm. Adicione um toque pessoal à sua moda com este deslumbrante colar com pingente da letra P. Não é apenas uma peça de joia, mas uma declaração de estilo única para você. Mostre ao mundo sua individualidade e elegância com essa inicial que representa tanto o seu nome quanto a sua personalidade.",
            parcel: "4x de 21,99",
            buy: "https://wa.link/frol5h"
        },
        {
            id: 123,
            name: "colar",
            image: require('../assets/produtos/colar-singapura-Q.jpeg'),
            image2: require('../assets/produtos/colar-singapura-Q.jpeg'),
            image3: require('../assets/produtos/colar-singapura-Q.jpeg'),
            title: "COLAR SINGAPURA Q",
            price: "99,99",
            priceNow: "R$ 87,99",
            overview: "Colar Singapura com pedras de zircônia - 45cm. Adicione um toque pessoal à sua moda com este deslumbrante colar com pingente da letra Q. Não é apenas uma peça de joia, mas uma declaração de estilo única para você. Mostre ao mundo sua individualidade e elegância com essa inicial que representa tanto o seu nome quanto a sua personalidade.",
            parcel: "4x de 21,99",
            buy: "https://wa.link/2zqkwg"
        },
        /*{
            id: 124,
            name: "colar",
            image: require('../assets/produtos/colar-singapura-R.jpeg'),
            image2: require('../assets/produtos/colar-singapura-R.jpeg'),
            image3: require('../assets/produtos/colar-singapura-R.jpeg'),
            title: "COLAR SINGAPURA R",
            price: "99,99",
            priceNow: "R$ 87,99",
            overview: "Colar Singapura com pedras de zircônia - 45cm. Adicione um toque pessoal à sua moda com este deslumbrante colar com pingente da letra R. Não é apenas uma peça de joia, mas uma declaração de estilo única para você. Mostre ao mundo sua individualidade e elegância com essa inicial que representa tanto o seu nome quanto a sua personalidade.",
            parcel: "4x de 21,99",
            buy: "https://wa.link/r632na"
        },
        {
            id: 125,
            name: "colar",
            image: require('../assets/produtos/colar-singapura-S.jpeg'),
            image2: require('../assets/produtos/colar-singapura-S.jpeg'),
            image3: require('../assets/produtos/colar-singapura-S.jpeg'),
            title: "COLAR SINGAPURA S",
            price: "99,99",
            priceNow: "R$ 87,99",
            overview: "Colar Singapura com pedras de zircônia - 45cm. Adicione um toque pessoal à sua moda com este deslumbrante colar com pingente da letra S. Não é apenas uma peça de joia, mas uma declaração de estilo única para você. Mostre ao mundo sua individualidade e elegância com essa inicial que representa tanto o seu nome quanto a sua personalidade.",
            parcel: "4x de 21,99",
            buy: "https://wa.link/xumj2v"
        },*/
        {
            id: 126,
            name: "colar",
            image: require('../assets/produtos/colar-singapura-T.jpeg'),
            image2: require('../assets/produtos/colar-singapura-T.jpeg'),
            image3: require('../assets/produtos/colar-singapura-T.jpeg'),
            title: "COLAR SINGAPURA T",
            price: "99,99",
            priceNow: "R$ 87,99",
            overview: "Colar Singapura com pedras de zircônia - 45cm. Adicione um toque pessoal à sua moda com este deslumbrante colar com pingente da letra T. Não é apenas uma peça de joia, mas uma declaração de estilo única para você. Mostre ao mundo sua individualidade e elegância com essa inicial que representa tanto o seu nome quanto a sua personalidade.",
            parcel: "4x de 21,99",
            buy: "https://wa.link/azr9vm"
        },
        /*{
            id: 127,
            name: "colar",
            image: require('../assets/produtos/colar-singapura-U.jpeg'),
            image2: require('../assets/produtos/colar-singapura-U.jpeg'),
            image3: require('../assets/produtos/colar-singapura-U.jpeg'),
            title: "COLAR SINGAPURA U",
            price: "99,99",
            priceNow: "R$ 87,99",
            overview: "Colar Singapura com pedras de zircônia - 45cm. Adicione um toque pessoal à sua moda com este deslumbrante colar com pingente da letra U. Não é apenas uma peça de joia, mas uma declaração de estilo única para você. Mostre ao mundo sua individualidade e elegância com essa inicial que representa tanto o seu nome quanto a sua personalidade.",
            parcel: "4x de 21,99",
            buy: "https://wa.link/kri2kr"
        },
        {
            id: 128,
            name: "colar",
            image: require('../assets/produtos/colar-singapura-V.jpeg'),
            image2: require('../assets/produtos/colar-singapura-V.jpeg'),
            image3: require('../assets/produtos/colar-singapura-V.jpeg'),
            title: "COLAR SINGAPURA V",
            price: "99,99",
            priceNow: "R$ 87,99",
            overview: "Colar Singapura com pedras de zircônia - 45cm. Adicione um toque pessoal à sua moda com este deslumbrante colar com pingente da letra V. Não é apenas uma peça de joia, mas uma declaração de estilo única para você. Mostre ao mundo sua individualidade e elegância com essa inicial que representa tanto o seu nome quanto a sua personalidade.",
            parcel: "4x de 21,99",
            buy: "https://wa.link/30l6d6"
        },*/
        {
            id: 129,
            name: "brinco",
            image: require('../assets/produtos/trio-argola-com-click.jpeg'),
            image2: require('../assets/produtos/trio-argola-com-click.jpeg'),
            image3: require('../assets/produtos/trio-argola-com-click.jpeg'),
            title: "TRIO DE ARGOLA",
            price: "169,99",
            priceNow: "R$ 156,99",
            overview: "Trio de argola com click. Renove seu estilo instantaneamente, versáteis, elegantes e práticas, essas argolas são perfeitas para qualquer ocasião. Adquira já o seu conjunto e adicione um toque de sofisticação ao seu visual.",
            parcel: "5x de 31,40",
            buy: "https://wa.link/b1n2ll"
        },
        {
            id: 130,
            name:"brinco",
            image: require('../assets/produtos/brinco-earcuff.jpeg'),
            image2: require('../assets/produtos/brinco-eracuff2.jpeg'),
            image3: require('../assets/produtos/brinco-earcuff.jpeg'),
            title: 'BRINCO EARCUFF',
            price: "69,99",
            priceNow: "R$ 55,99",
            overview: "Brinco Earcuff 3 Corações. Adicione um toque de elegância e estilo ao seu visual com nosso brinco exclusivo! Feito com materiais de alta qualidade e design sofisticado, este brinco é perfeito para qualquer ocasião, seja para o dia a dia ou para uma noite especial.",
            parcel: "5x de 11,20",
            buy: 'https://wa.link/s3pghr'
        },
        {
            id: 131,
            name: "brinco",
            image: require('../assets/produtos/brinco-coracao-medio.jpeg'),
            image2: require('../assets/produtos/brinco-coracao-medio.jpeg'),
            image3: require('../assets/produtos/brinco-coracao-medio.jpeg'),
            title: "BRINCO CORAÇÃO MÉDIO",
            price: "109,99",
            priceNow: "R$ 91,99",
            overview: "Seu visual merece um toque de elegância e personalidade, e nosso Brinco coração médio azul céu é a escolha ideal para isso.",
            parcel: "5x de 18,40",
            buy: "https://wa.link/1ipqrn"
        },
        {
            id: 132,
            name: "brinco",
            image: require('../assets/produtos/brinco-coracao-grande.jpeg'),
            image2: require('../assets/produtos/brinco-coracao-grande.jpeg'),
            image3: require('../assets/produtos/brinco-coracao-grande.jpeg'),
            title: "BRINCO CORAÇÃO GRANDE",
            price: "119,99",
            priceNow: "R$ 97,99",
            overview: "Brinco Coração Grande Azul. Seja para um encontro romântico ou uma saída casual com amigos, este brinco adicionará um toque de charme único ao seu visual. Não perca a oportunidade de se destacar, adquira o seu agora e deixe sua beleza brilhar!",
            parcel: "5x de 19,60",
            buy: "https://wa.link/hm6mef"
        },
        {
            id: 133,
            name: "brinco",
            image: require('../assets/produtos/brinco-coracao-turmalina.jpeg'),
            image2: require('../assets/produtos/brinco-coracao-turmalina.jpeg'),
            image3: require('../assets/produtos/brinco-coracao-turmalina.jpeg'),
            title: "BRINCO CORAÇÃO TURMALINA",
            price: "79,99",
            priceNow: "R$ 61,99",
            overview: "Brinco Coração Turmalina com ponto de luz. Transforme seu look com o brilho e charme irresistíveis do nosso novo brinco. Feito com cuidado artesanal e detalhes encantadores, este brinco é a peça que falta para completar seu estilo único.",
            parcel: "5x de 12,40",
            buy: "https://wa.link/feu18f"
        },
        {
            id: 134,
            name: "brinco",
            image: require('../assets/produtos/brinco-coracao-crista-zirconia.jpeg'),
            image2: require('../assets/produtos/brinco-coracao-crista-zirconia.jpeg'),
            image3: require('../assets/produtos/brinco-coracao-crista-zirconia.jpeg'),
            title: "BRINCO CORAÇÃO CRISTAL COM ZIRCÔNIA",
            price: "109,99",
            priceNow: "R$ 89,99",
            overview: "Brinco Coração Cristal com Zircônia. Seja para uma noite especial ou para um dia casual, ele adiciona um toque de elegância e personalidade. Não perca a chance de se destacar, adquira o seu agora e deixe sua beleza brilhar.",
            parcel: "5x de 17,80",
            buy: "https://wa.link/ttncx8"
        },
        {
            id: 135,
            name: "brinco",
            image: require('../assets/produtos/brinco-asas-de-borboleta1.jpeg'),
            image2: require('../assets/produtos/brinco-asas-de-borboleta2.jpeg'),
            image3: require('../assets/produtos/brinco-asas-de-borboleta1.jpeg'),
            title: "BRINCO ASAS DE BORBOLETA",
            price: "99,99",
            priceNow: "R$ 88,99",
            overview: "Brinco Asas de Borboleta. Realce sua beleza com nosso deslumbrante brinco. Com um design moderno e acabamento impecável, este acessório é perfeito para adicionar um toque de glamour ao seu visual. ",
            parcel: "5x de 17,80",
            buy: "https://wa.link/5wsci1"
        },
        {
            id: 136,
            name: "brinco",
            image: require('../assets/produtos/brinco-argola-borboleta.jpeg'),
            image2: require('../assets/produtos/brinco-argola-borboleta2.jpeg'),
            image3: require('../assets/produtos/brinco-argola-borboleta.jpeg'),
            title: "BRINCO ARGOLA BORBOLETA",
            price: "69,99",
            priceNow: "R$ 48,99",
            overview: "Em busca de um toque de estilo e personalidade para seus looks? Apresentamos o Brinco Argola Borboleta, a escolha perfeita para expressar sua individualidade com elegância.",
            parcel: "5x de 9,80",
            buy: "https://wa.link/ppykf3"
        },
        {
            id: 137,
            name: "brinco",
            image: require('../assets/produtos/brinco-earcuff-folhas.jpeg'),
            image2: require('../assets/produtos/brinco-earcuff-folhas2.jpeg'),
            image3: require('../assets/produtos/brinco-earcuff-folhas.jpeg'),
            title: "BRINCO EARCUFF",
            price: "69,99",
            priceNow: "R$ 49,99",
            overview: "Brinco Earcuff Folhas. Feito com detalhes cuidadosamente elaborados e materiais de alta qualidade, este brinco não apenas adiciona charme instantâneo, mas também é uma declaração de moda por si só. ",
            parcel: "5x de 9,99",
            buy: "https://wa.link/elzu9p"
        },
        {
            id: 138,
            name: "brinco",
            image: require('../assets/produtos/brinco-earcuff-pontinhos-de-luz.jpeg'),
            image2: require('../assets/produtos/brinco-earcuff-pontinhos-de-luz-2.jpeg'),
            image3: require('../assets/produtos/brinco-earcuff-pontinhos-de-luz.jpeg'),
            title: "BRINCO EARCUFF",
            price: "67,99",
            priceNow: "R$ 46,99",
            overview: "Realce sua beleza com o toque de glamour do nosso novo Brinco Earcuff pontinhos de luz. Feito com detalhes artesanais e uma elegância única, este brinco é mais do que uma simples peça de joia, é uma declaração de estilo.",
            parcel: "5x de 9,40",
            buy: "https://wa.link/mpf3ae"
        },
        {
            id: 139,
            name: "brinco",
            image: require('../assets/produtos/argola-coracao-pequeno.jpeg'),
            image2: require('../assets/produtos/argola-coracao-pequeno.jpeg'),
            image3: require('../assets/produtos/argola-coracao-pequeno.jpeg'),
            title: "ARGOLA CORAÇÃO",
            price: "47,99",
            priceNow: "R$ 37,99",
            overview: "Argola Coração Pequeno fio quadrado - 2,3cm. Complete seu look com a sofisticação e o charme atemporal de nossa argola. Feita com materiais de qualidade e design elegante, esta argola é a peça essencial que falta em sua coleção de acessórios. ",
            parcel: "5x de 7,60",
            buy: "https://wa.link/2x4v8w"
        },
        {
            id: 140,
            name: "brinco",
            image: require('../assets/produtos/argola-coracao-grande.jpeg'),
            image2: require('../assets/produtos/argola-coracao-grande.jpeg'),
            image3: require('../assets/produtos/argola-coracao-grande.jpeg'),
            title: "ARGOLA CORAÇÃO",
            price: "79,99",
            priceNow: "R$ 60,99",
            overview: "Argola Coração Grande fio quadrado - 4,1cm. Seja para um encontro casual ou uma noite especial, esta argola vai adicionar um toque de estilo e elegância a qualquer visual. Não espere mais para elevar seu estilo, adquira a sua agora e deixe sua beleza brilhar",
            parcel: "5x de 12,20",
            buy: "https://wa.link/grhch8"
        },
        {
            id: 141,
            name: "brinco",
            image: require('../assets/produtos/argola-torcida-media.jpeg'),
            image2: require('../assets/produtos/argola-torcida-media.jpeg'),
            image3: require('../assets/produtos/argola-torcida-media.jpeg'),
            title: "ARGOLA TORCIDA",
            price: "69,99",
            priceNow: "R$ 55,99",
            overview: "Argola Torcida Média. Complete seu visual com um toque de sofisticação e estilo com nossa deslumbrante argola. Feita com acabamento impecável e design moderno.",
            parcel: "5x de 11,20",
            buy: "https://wa.link/ij36l6"
        },
        {
            id: 142,
            name: "brinco",
            image: require('../assets/produtos/brinco-lua-medio.jpeg'),
            image2: require('../assets/produtos/brinco-lua-medio.jpeg'),
            image3: require('../assets/produtos/brinco-lua-medio.jpeg'),
            title: "BRINCO LUA MÉDIO",
            price: "39,99",
            priceNow: "R$ 33,99",
            overview: "Realce sua beleza com nosso encantador Brinco Lua Médio fio quadrado. Com um design delicado e elegante, este brinco é perfeito para adicionar um toque de glamour ao seu visual.",
            parcel: "5x de 6,80",
            buy: "https://wa.link/0h3zyb"
        },
        {
            id: 143,
            name: "brinco",
            image: require('../assets/produtos/brinco-lua-pequeno.jpeg'),
            image2: require('../assets/produtos/brinco-lua-pequeno.jpeg'),
            image3: require('../assets/produtos/brinco-lua-pequeno.jpeg'),
            title: "BRINCO LUA PEQUENO",
            price: "34,99",
            priceNow: "R$ 29,99",
            overview: "Brinco Lua pequeno fio quadrado. Seja para uma ocasião especial ou para o dia a dia, este brinco vai complementar seu estilo de forma única. Feito com materiais de alta qualidade, é a escolha ideal para quem busca sofisticação e charme.",
            parcel: "5x de 6,00",
            buy: "https://wa.link/fhf6r3"
        },
        {
            id: 144,
            name: "brinco",
            image: require('../assets/produtos/brinco-lua-grande.jpeg'),
            image2: require('../assets/produtos/brinco-lua-grande.jpeg'),
            image3: require('../assets/produtos/brinco-lua-grande.jpeg'),
            title: "BRINCO LUA GRANDE",
            price: "49,99",
            priceNow: "R$ 40,99",
            overview: "Adicione um toque de brilho e charme ao seu visual com nosso encantador Brinco Lua Grande fio quadrado. Feito com materiais de qualidade e um design elegante, este brinco é a escolha perfeita para realçar sua beleza única.",
            parcel: "5x de 8,20",
            buy: "https://wa.link/37zngs"
        },
        {
            id: 145,
            name: "brinco",
            image: require('../assets/produtos/brinco-3-gotas.jpeg'),
            image2: require('../assets/produtos/brinco-3-gotas.jpeg'),
            image3: require('../assets/produtos/brinco-3-gotas.jpeg'),
            title: "BRINCO 3 GOTAS",
            price: "99,99",
            priceNow: "R$ 82,99",
            overview: "Brinco 3 Gotas. Seja para uma festa glamorosa ou uma saída casual, este brinco vai completar seu look com estilo.",
            parcel: "5x de 16,60",
            buy: "https://wa.link/bigrzf"
        },
        {
            id: 146,
            name: "brinco",
            image: require('../assets/produtos/argola-dupla-corrente.jpeg'),
            image2: require('../assets/produtos/argola-dupla-corrente.jpeg'),
            image3: require('../assets/produtos/argola-dupla-corrente.jpeg'),
            title: "ARGOLA DUPLA CORRENTE",
            price: "139,99",
            priceNow: "R$ 111,99",
            overview: "Argola Dupla Corrente - 5,3cm único. Seja para uma ocasião especial ou para o dia a dia, esta argola vai complementar o seu look com charme e elegância.",
            parcel: "5x de 22,40",
            buy: "https://wa.link/tcyy6r"
        },
        {
            id: 147,
            name: "brinco",
            image: require('../assets/produtos/argolinha-navete.jpeg'),
            image2: require('../assets/produtos/argolinha-navete.jpeg'),
            image3: require('../assets/produtos/argolinha-navete.jpeg'),
            title: "ARGOLINHA NAVETE",
            price: "99,99",
            priceNow: "R$ 81,99",
            overview: "Argolinha Navete verde - 1,2cm. Descubra a beleza delicada e versátil da nossa argolinha. Com um design minimalista e elegante, este acessório é perfeito para adicionar um toque de estilo discreto a qualquer look.",
            parcel: "5x de 16,40",
            buy: "https://wa.link/vcfa2a"
        },
        {
            id: 148,
            name: "brinco",
            image: require('../assets/produtos/argola-corrente-pingo-verde.jpeg'),
            image2: require('../assets/produtos/argola-corrente-pingo-verde.jpeg'),
            image3: require('../assets/produtos/argola-corrente-pingo-verde.jpeg'),
            title: "ARGOLA CORRENTE",
            price: "109,99",
            priceNow: "R$ 88,99",
            overview: "Argola corrente pingo gota verde claro. Seja para o trabalho ou para um evento casual, esta argolinha é a escolha ideal para complementar o seu visual com simplicidade e sofisticação. Adquira a sua hoje e desfrute da versatilidade e elegância que ela proporciona!",
            parcel: "5x de 17,80",
            buy: "https://wa.link/tv4sx4"
        },
        {
            id: 149,
            name: "colar",
            image: require('../assets/produtos/colar-coracao-verde-agua.jpeg'),
            image2: require('../assets/produtos/colar-coracao-verde-agua.jpeg'),
            image3: require('../assets/produtos/colar-coracao-verde-agua.jpeg'),
            title: "COLAR CORAÇÃO VERDE",
            price: "109,99",
            priceNow: "R$ 88,99",
            overview: 'Colar Coração Verde Água. Destaque-se na multidão com elegância e estilo incomparáveis! Adquira agora o nosso deslumbrante colar, a peça perfeita para elevar qualquer visual.',
            parcel: "5x de 17,80",
            buy: 'https://wa.link/jzxfrt'
        },
        {
            id: 150,
            name: "colar",
            image: require('../assets/produtos/colar-pingente-gota-fusion.jpeg'),
            image2: require('../assets/produtos/colar-pingente-gota-fusion.jpeg'),
            image3: require('../assets/produtos/colar-pingente-gota-fusion.jpeg'),
            title: 'COLAR PINGENTE GOTA',
            price: "169,99",
            priceNow: "R$ 143,99",
            overview: "Colar Pingente Gota Fusion Cristal. Sinta a confiança e a sofisticação que só um acessório de qualidade pode oferecer. Não perca a oportunidade de brilhar, adquira já o seu!",
            parcel: "5x de 28,80",
            buy: "https://wa.link/c2bczo"
        },
        {
            id: 151,
            name: 'colar',
            image: require('../assets/produtos/choker-elo-tijolinho-pingente.jpeg'),
            image2: require('../assets/produtos/choker-elo-tijolinho-pingente.jpeg'),
            image3: require('../assets/produtos/choker-elo-tijolinho-pingente.jpeg'),
            title: "CHOKER ELO TIJOLINHO",
            price: "118,99",
            priceNow: "R$ 98,99",
            overview: 'Choker Elo Tijolinho Pingente Coração Vazado. Transforme seu visual com elegância e estilo. Adquira agora o colar choker que vai realçar sua beleza e expressar sua personalidade única. Não deixe essa oportunidade passar, adquira já o seu!',
            parcel: '5x de 19,80',
            buy: 'https://wa.link/v8s2zl'
        },
        {
            id: 152,
            name: 'colar',
            image: require('../assets/produtos/colar-singapura-pingente-pedra.jpeg'),
            image2: require('../assets/produtos/colar-singapura-pingente-pedra.jpeg'),
            image3: require('../assets/produtos/colar-singapura-pingente-pedra.jpeg'),
            title: "COLAR PINGENTE TURMALINA",
            price: '129,99',
            priceNow: 'R$ 109,99',
            overview: "Colar Singapura Pingente Pedra Turmalina e Ponto de Luz. Seja notado onde quer que vá com o toque de elegância e sofisticação que apenas um belo colar pode oferecer. Deixe sua personalidade brilhar através de cada detalhe, adornando-se com este colar deslumbrante.",
            parcel: '5x de 22,00',
            buy: 'https://wa.link/f5ge83'
        },
        {
            id: 153,
            name: 'colar',
            image: require('../assets/produtos/choker-veneziana-coracao-cristal.jpeg'),
            image2: require('../assets/produtos/choker-veneziana-coracao-cristal.jpeg'),
            image3: require('../assets/produtos/choker-veneziana-coracao-cristal.jpeg'),
            title: "CHOKER VENEZIANA",
            price: '103,99',
            priceNow: "R$ 93,99",
            overview: 'Choker Veneziana Coração Cristal. Não perca a oportunidade de adicionar um toque de glamour ao seu estilo único. Adquira agora e transforme cada momento em uma ocasião especial.',
            parcel: '5x de 18,80',
            buy: 'https://wa.link/hwepg9'
        },
        {
            id: 154,
            name: 'colar',
            image: require('../assets/produtos/colar-50cm-veneziana.jpeg'),
            image2: require('../assets/produtos/colar-50cm-veneziana.jpeg'),
            image3: require('../assets/produtos/colar-50cm-veneziana.jpeg'),
            title: 'COLAR VENEZIANA',
            price: '99,99',
            priceNow: 'R$ 89,99',
            overview: 'Colar 50cm Veneziana Pingente Coração Azul Céu. Desperte sua elegância com nosso colar exclusivo! Feito com materiais de alta qualidade e design sofisticado, este colar adicionará um toque de glamour a qualquer look.',
            parcel: '5x de 18,00',
            buy: 'https://wa.link/qv2ved'
        },
        {
            id: 155,
            name: 'colar',
            image: require('../assets/produtos/choker-pingente-ponto-de-luz-verde.jpeg'),
            image2: require('../assets/produtos/choker-pingente-ponto-de-luz-verde.jpeg'),
            image3: require('../assets/produtos/choker-pingente-ponto-de-luz-verde.jpeg'),
            title: 'CHOKER PONTO DE LUZ VERDE',
            price: '95,99',
            priceNow: "R$ 75,99",
            overview: 'Choker Pingente Pontos de Luz Verde. Seja para uma ocasião especial ou para o dia a dia, este acessório é a escolha perfeita para quem busca estilo e distinção. Não perca a chance de elevar o seu visual com este choker deslumbrante. Garanta o seu agora e brilhe com confiança!',
            parcel: '5x de 15,20',
            buy: 'https://wa.link/hd921b'
        },
        {
            id: 156,
            name: 'colar',
            image: require('../assets/produtos/choker-pingente-ponto-de-luz-azul.jpeg'),
            image2: require('../assets/produtos/choker-pingente-ponto-de-luz-azul.jpeg'),
            image3: require('../assets/produtos/choker-pingente-ponto-de-luz-azul.jpeg'),
            title: 'CHOKER PONTO DE LUZ AZUL',
            price: '95,99',
            priceNow: "R$ 75,99",
            overview: 'Choker Pingente Pontos de Luz Azul. Complete seu estilo com nosso choker único! Feito para os apaixonados por moda que desejam destacar seu visual com um toque de ousadia e elegância. Com seu design moderno e detalhes encantadores, este choker é a escolha ideal para adicionar um toque de sofisticação a qualquer conjunto.',
            parcel: '5x de 15,20',
            buy: 'https://wa.link/2os195'
        },
        {
            id: 157,
            name: 'colar',
            image: require('../assets/produtos/choker-veneziana-pingente-gota-fusion.jpeg'),
            image2: require('../assets/produtos/choker-veneziana-pingente-gota-fusion.jpeg'),
            image3: require('../assets/produtos/choker-veneziana-pingente-gota-fusion.jpeg'),
            title: 'CHOKER VENEZIANA FUSION',
            price: '133,99',
            priceNow: 'R$ 113,99',
            overview: 'Choker Veneziana Pingente Gota Fusion Azul. Seja para uma noite especial ou para um look casual, este acessório versátil é garantia de estilo. Não deixe escapar a oportunidade de aprimorar sua coleção de acessórios com nosso choker exclusivo. Adquira o seu agora e deixe sua personalidade brilhar!',
            parcel: '5x de 22,80',
            buy: 'https://wa.link/1pryfs'
        },
        {
            id: 158,
            name: 'colar',
            image: require('../assets/produtos/colar-45cm-singapura-coracao-azul.jpeg'),
            image2: require('../assets/produtos/colar-45cm-singapura-coracao-azul.jpeg'),
            image3: require('../assets/produtos/colar-45cm-singapura-coracao-azul.jpeg'),
            title: 'COLAR SINGAPURA CORAÇÃO AZUL',
            price: '117,99',
            priceNow: 'R$ 97,99',
            overview: 'Colar 45cm Singapura Pingente Coração Azul Céu. Transforme seu visual com o nosso deslumbrante colar! Feito com materiais de alta qualidade e um design que exala elegância, este colar é o acessório perfeito para realçar sua beleza natural. ',
            parcel: '5x de 19,60',
            buy: 'https://wa.link/xit9gw'
        },
        {
            id: 159,
            name: 'masculino',
            image: require('../assets/produtos/corrente-groumett-5gr-70cm.jpeg'),
            image2: require('../assets/produtos/corrente-groumett-5gr-70cm.jpeg'),
            image3: require('../assets/produtos/corrente-groumett-5gr-70cm.jpeg'),
            title: 'CORRENTE GROUMETT 5GR',
            price: '139,99',
            priceNow: "R$ 129,99",
            overview: 'Corrente Groumett 5gr. 70cm. Exiba sua masculinidade com estilo com nossa corrente exclusiva! Feita para homens que valorizam qualidade e elegância, esta corrente é o acessório perfeito para complementar seu visual.',
            parcel: '5x de 26,00',
            buy: 'https://wa.link/qz5fdf'
        },
        {
            id: 160,
            name: 'masculino',
            image: require('../assets/produtos/pulseira-groumett-21cm.jpeg'),
            image2: require('../assets/produtos/pulseira-groumett-21cm.jpeg'),
            image3: require('../assets/produtos/pulseira-groumett-21cm.jpeg'),
            title: "PULSEIRA GROUMETT",
            price: '94,99',
            priceNow: "R$ 73,99",
            overview: 'Pulseira Groumett 21cm. Destaque-se com nossa pulseira exclusiva! Feita com materiais de alta qualidade e um design moderno, esta pulseira é o acessório perfeito para complementar seu estilo único.',
            parcel: '5x de 14,80',
            buy: 'https://wa.link/b9zs8u'
        },
        {
            id: 161,
            name: 'masculino',
            image: require('../assets/produtos/pulseira-elo-3x1.jpeg'),
            image2: require('../assets/produtos/pulseira-elo-3x1.jpeg'),
            image3: require('../assets/produtos/pulseira-elo-3x1.jpeg'),
            title: 'PULSEIRA ELO 3X1',
            price: '99,99',
            priceNow: 'R$ 86,99',
            overview: 'Pulseira Elo 3x1 19cm. Mostre sua personalidade e bom gosto com esta pulseira versátil e deslumbrante. Não perca a oportunidade de adicionar um toque especial ao seu visual. Adquira a sua agora e brilhe com confiança!',
            parcel: '5x de 17,40',
            buy: 'https://wa.link/n78nzj'
        },
        {
            id: 162,
            name: 'masculino',
            image: require('../assets/produtos/pulseira-elo-tijolinho.jpeg'),
            image2: require('../assets/produtos/pulseira-elo-tijolinho.jpeg'),
            image3: require('../assets/produtos/pulseira-elo-tijolinho.jpeg'),
            title: 'PULSEIRA ELO TJOLINHO',
            price: '67,99',
            priceNow: "R$ 51,99",
            overview: 'Pulseira Elo Tijolinho 21cm. Complete seu estilo com nossa pulseira exclusiva! Feita para os amantes de moda que buscam um toque de elegância e personalidade em seu visual. Com materiais de alta qualidade e um design cuidadosamente elaborado, esta pulseira é o acessório perfeito para adicionar um toque de sofisticação a qualquer look.',
            parcel: '5x de 10,40',
            buy: 'https://wa.link/3e3han'
        },
        {
            id: 163,
            name: 'masculino',
            image: require('../assets/produtos/pulseira-elo-cartier.jpeg'),
            image2: require('../assets/produtos/pulseira-elo-cartier.jpeg'),
            image3: require('../assets/produtos/pulseira-elo-cartier.jpeg'),
            title: 'PULSEIRA ELO CARTIER',
            price: '79,99',
            priceNow: 'R$ 67,99',
            overview: 'Pulseira Elo Cartier. Seja para o dia a dia ou para uma ocasião especial, esta pulseira versátil é garantia de estilo e charme. Não deixe escapar a oportunidade de aprimorar seu estilo com nossa pulseira única. Adquira a sua agora e faça uma declaração de moda que não passará despercebida!',
            parcel: '5x de 13,60',
            buy: 'https://wa.link/x9iro6'
        },
        {
            id: 164,
            name: 'masculino',
            image: require('../assets/produtos/pulseira-elo-groumett-20cm.jpeg'),
            image2: require('../assets/produtos/pulseira-elo-groumett-20cm.jpeg'),
            image3: require('../assets/produtos/pulseira-elo-groumett-20cm.jpeg'),
            title: 'PULSEIRA ELO GROUMETT 20CM',
            price: '129,99',
            priceNow: 'R$ 117,99',
            overview: 'Pulseira Elo Groumett 20cm. Complete seu visual com nossa pulseira exclusiva! Feita para quem busca estilo e sofisticação, esta pulseira é o toque final perfeito para qualquer look. Com um design elegante e materiais de alta qualidade, esta pulseira é a escolha ideal para adicionar um toque de charme ao seu pulso',
            parcel: '5x de 23,60',
            buy: 'https://wa.link/g581ij'
        }
    ],
};
export default data;